import firebase from "firebase/compat/app"
import 'firebase/compat/storage'

const firebaseConfig = {
  apiKey: "AIzaSyAXVFWsUrJu4uFOZp8vbSAoLw85b9Tz4oE",
  authDomain: "cajamar-b44d7.firebaseapp.com",
  projectId: "cajamar-b44d7",
  storageBucket: "cajamar-b44d7.appspot.com",
  messagingSenderId: "504340887721",
  appId: "1:504340887721:web:ed951cfe4112053cf091b8",
  measurementId: "G-5FEVVBCS07"
};

  
  // Initialize Firebase
  const app = firebase.initializeApp(firebaseConfig);


export default app