import React from 'react'
import ThowColumLayoutInventary from '../../layouts/ThowColumLayoutInventary'
import Sidebar from '../../components/Sidebar'
import TableListUser from '../../components/TableListUser'
import FilterUsers from '../../components/FilterUsers'
import HeaderDashboard from '../../components/HeaderDashboard'
import { useTranslation } from 'react-i18next'

const Users = () => {
    const {t} = useTranslation()
    const containerSecondary = {

        marginTop:"1.625rem",
        display:"flex",
        flexDirection:"column",
        overFlowY:"scroll",
        alignItems:"center",
        overFlowX:"hidden",

    }

    return(

        <ThowColumLayoutInventary
        name="Gerenciar Acessos"
        page="Users"
        height={"auto"}
        background="#F9F9F9"
        colum1={
            <Sidebar page="Users"/>
        }

        colum2={
            <>
            <div style={containerSecondary}>

            <div style={{width:"1000px",display:"flex",alignItems:"center",justifyContent:"center"}}>

            <div style={{width:"1020px"}}>
            <HeaderDashboard name={t('accountManagement')}/>
            <FilterUsers/>
            <TableListUser/>            
            </div>

            </div>

            </div>
            </>
        }

        />

    )

}

export default Users