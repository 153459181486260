import React, { useState, useEffect, useContext } from 'react'
import api from '../../services/api'
import { ClientContext } from '../../context/client'
import { FilterContext} from '../../context/filter'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "react-tooltip/dist/react-tooltip.css";

import InputUsers from '../InputUsers'
import InputData from '../InputData'
import { useTranslation } from 'react-i18next'
import homeIcon from '../../images/png/fachadas-de-casas-000.webp'
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import notFoundIcon from '../../images/png/House searching-cuate.png'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'



const TableListManager = ({ page,type }) => {
    const {t} = useTranslation()

    const navigate = useNavigate()

    /* CLIENT CONTEXT */
    const { dataClient} = useContext(ClientContext)
    const { dataFilter} = useContext(FilterContext)

    const [data2,setData2] = useState([])
    const [valueArray, setValueArray] = useState()
    const [idEdit, setIdEdit] = useState();
    const [idRemove, setIdRemove] = useState();

    const [modalState,setModalState] = useState(false)
    const [animation,setAnimation] = useState(null)

    const get = async (type_page) => {

        if(type_page == "request"){
            const response = await api.get("panel/immobiles/user")
            if(response.status == 404){
                setAnimation(false)
                return false
            }
            setTimeout(() => {
                setAnimation(false)
    
            },[2000])
            setData2(response.data)
            return false
        }
    
        const response = await api.get("/panel/immobiles")

        if(response.status == 404){
            setAnimation(false)
            return false
        }
        setTimeout(() => {
            setAnimation(false)

        },[1000])

        setData2(response.data)
    };
    const approve = async () =>{ 
        
        const response = await api.put("panel/update/immobile/",{
          
            id:idEdit,
            status:null
        })

        if(response.status == 200){
            toast.success('Imóvel aprovado com sucesso!!', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

            
        setTimeout(() => {

            window.location.href="/dashboard/inventary"

        },[1800])

        }

    }
    const remove = async () => {
        const response = await api.delete(`panel/immobiles/delete/${idRemove}`)

        if(response.status == 200){
            toast.success('Imóvel deletado com sucesso!!', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }

        setTimeout(() => {

            window.location.href="/dashboard/inventary"

        },[1800])

    }
    const redirectToEditPage = () => {
        localStorage.setItem("@ID_EDIT",idEdit)
        navigate("/dashboard/edit/stock")
    }


    useEffect(() => {
        
        get(type)

    },[type])

    return (

        <>
            <div id="test2" style={{ height: `${valueArray < 5 || valueArray == 0 || String(dataClient)?.length == 0 ? "100vh" : "100%"}`, overflowX: "hidden", overflowY: "hidden", marginBottom: "100px" }}>
                <ToastContainer />

                <InputUsers onClick={() => { setIdEdit(0) }} id={0} client={0} />

                {dataFilter 
                
                ?
                dataFilter.map(item => {
                    return(


                        <div onClick={() => {setModalState(!modalState);setIdRemove(item.id);setIdEdit(item.id_prop)}}>
                        <InputData name={item.name}  id_prop={item.id_prop} goal={item.goal} code={item.code} location={item.location} type={item.type} description={item.description} bathroom={item.bathroom_num} district="Test" iptu={item.iptu} m2={item.square_meters}  icon={homeIcon} price={item.value} onClick={() => {  setIdEdit(0) }} id={0} client={0} />                            
                        </div>

                    )
                })

                :

                animation == null 
                
                ?
                <Skeleton style={{height:"50px"}}></Skeleton>
                :

                page != "request"
                ?

                data2.map(item => {
                    return(
                    
                        <div onClick={() => {setModalState(!modalState);setIdRemove(item.id);setIdEdit(item.id_prop)}}>
                        <InputData name={item.name}  id_prop={item.id_prop} goal={item.goal} code={item.code} location={item.location} type={item.type} description={item.description} bathroom={item.bathroom_num} district="Test" iptu={item.iptu} m2={item.square_meters}  icon={homeIcon} price={item.value} onClick={() => {  setIdEdit(0) }} id={0} client={0} />                            
                        </div>

                    )
                })

                :
                <></>

                }

                {data2.length == 0 && animation == false 
                
                ?
                <div className='container_image_not_found'>
                <img className='notFoundIcon' src={notFoundIcon}/>
                <p>Nenhum imóvel foi encontrado!</p>
                </div>
                :
                <></>
                }

        



            </div>
            

            <Modal
                dialogClassName="modal-filter-table-list-user-edit"
                show={modalState}
                animation={true}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header >

                    <div style={{ display: "flex", height: "30px", justifyContent: "space-between", width: "100%" }}>

                        <p className="textPrimaryModalFilterManager" style={{ position: "relative", right: "17px" }}>{t('actions')}</p>

                        <div onClick={() => setModalState(false)} style={{ cursor: "pointer" }}>
                            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_0_2887)">
                                    <path d="M7.49864 6.3679L12.5016 1.35941C12.8122 1.04847 12.8122 0.544354 12.5016 0.233445C12.1911 -0.0774893 11.6876 -0.0774893 11.377 0.233445L6.37456 5.24246L1.37211 0.233445C1.06155 -0.0774893 0.55804 -0.0774893 0.247506 0.233445C-0.0630274 0.544378 -0.0630522 1.0485 0.247506 1.35941L5.25048 6.3679L0.247506 11.3764C-0.0630522 11.6874 -0.0630522 12.1915 0.247506 12.5024C0.558065 12.8133 1.06158 12.8133 1.37211 12.5024L6.37456 7.49334L11.377 12.5024C11.6876 12.8133 12.1911 12.8133 12.5016 12.5024C12.8122 12.1915 12.8122 11.6873 12.5016 11.3764L7.49864 6.3679Z" fill="black" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_0_2887">
                                        <rect width="12.72" height="12.7354" fill="white" transform="translate(0.0143433)" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>

                    </div>


                </Modal.Header>
                <Modal.Body style={{ padding: "0px" }}>

                    <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>

                        <div onClick={() => {redirectToEditPage()}} style={{ height: "55px", width: "100%", borderBottom: "1px solid #D7D7D7", textAlign: "center", paddingTop: "0.9rem", cursor: "pointer" }}>
                            <p className="textModalTableListUserEditPrimary">{t('Editar ')}</p>
                        </div>

                        {localStorage.getItem("@USER_PERMISSION") == "Cliente" 
                        
                        ?
                        <></>
                        :


                        <>
                        {type == "request" 
                        
                        ?
                        <div onClick={() => approve()} style={{ height: "55px", width: "100%", borderBottom: "1px solid #D7D7D7", textAlign: "center", paddingTop: "0.9rem", cursor: "pointer" }}>
                        <p className='textModalTableListUserEditPrimary'>{t('Aprovar')}</p>
                        </div>
                        :
                        <></>
                        }
                        <div onClick={() => remove()} style={{ height: "55px", width: "100%", borderBottom: "1px solid #D7D7D7", textAlign: "center", paddingTop: "0.9rem", cursor: "pointer" }}>
                        <p className='textModalTableListUserEditSecondary'>{t('Remover ')}</p>
                        </div>
                        </>
                        }
                          
                        
                        <div style={{ height: "70px" }}></div>

                    </div>

                </Modal.Body>



            </Modal>

        </>
    )
}

export default TableListManager