import React, { useState,useEffect } from 'react'
import api from '../../services/api'
import { ToastContainer, toast } from 'react-toastify';

const InputCheckbox = ({ className,page, name, type, placeholder, onChange, width, error, height, marginLeft, color, money, value, rightEye, topEye, mask, disabled, multiple, style,id }) => {

    const [checked,setChecked] = useState(false)
    const [notChecked,setNotChecked] = useState(false)

    const containerInput = {

        display: "flex",
        flexDirection: "column",
        marginLeft: marginLeft,
        height:"47px",
        width: width,
    }
    const styleInput = {

        width: width != undefined ? width : "418px",
        color: color != undefined ? color : "",
        height: height != undefined ? height : "50px",
        borderRadius: "10px",
        border: "1px solid #D7D7D7",
        marginBottom: "1.9rem",
        outline: "none",
        backgroundColor: disabled && "#f4f4f4"
    }
    const styleLabel = {
        marginBottom: "14.43px"
    }
    const containerInputMail = {
        width: width != undefined ? width : "418px",

        display: "flex",
        flexDirection: "column",
        position: "relative",

    }
    const containerInputPassowrd = {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        width: width != undefined ? width : "418px",

    }
    const image = {

        position: "absolute",
        right: 0,
        top: topEye != undefined ? topEye : "50px",
        right: rightEye != undefined ? rightEye : "10px",
        cursor: "pointer"
    }

    const get = async () => {
        const response = await api.get(`client/immobiles/character/by/immobile/${localStorage.getItem("@ID_EDIT")}`)
        response.data.map(item => {
            if(item.name == id){
                setChecked(true)
            }
        })
    }

    const handleCheckboxChange = async (id) => {
        // Inverte o valor do estado checked quando o checkbox é clicado
        setChecked(!checked);
        
        if(checked == true){
            
            
            toast.info('Removendo Característica!', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

            const response = await api.post("panel/immobile/characters",{
                "name":id,
                "id_prop":localStorage.getItem("@ID_EDIT")
            })
            
        }else{
            
            toast.info('Adicionando Característica!', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

            const response = await api.post("panel/storage/immobile/character",{
                "name":id,
                "id_prop":localStorage.getItem("@ID_EDIT")
            })
        }

        // Chama a função onChange, se fornecida, com o novo valor do checkbox
        if (onChange) {
            onChange(!checked);
        }
    };

    useEffect(() => {
        get()


    },[id])

    return (

        page == "edit" 
        
        ?

        
        <div style={containerInput}>
            <div style={containerInputMail}>
         
                <div class="form-check form-switch">
                <input onChange={() => handleCheckboxChange(id)} checked={checked} value={id} class="form-check-input" type="checkbox" id={id}/>
                <label class="form-check-label"va for={id}>{name == "Pet" ? "Aceita" : "Possui"} {name}?</label>
                </div>

            </div>
        </div>

        :

        
        name == "Pet" 
        
        ?
        <div style={containerInput}>
        <div style={containerInputMail}>

            
     
            <div class="form-check form-switch">
            <input onChange={onChange}  value={id} class="form-check-input" type="checkbox" id={id}/>
            <label class="form-check-label" for={id}>Aceita {name}?</label>
            </div>

        </div>
    </div>
        :
        <div style={containerInput}>
            <div style={containerInputMail}>
         
                <div class="form-check form-switch">
                <input onChange={onChange}  value={id} class="form-check-input" type="checkbox" id={id}/>
                <label class="form-check-label" for={id}>Possui {name}?</label>
                </div>

            </div>
        </div>

    )

}

export default InputCheckbox