import React,{useState} from 'react'
import closeEyeIcon from '../../images/svg/closeEyeIcon.svg'
import openEyeIcon from '../../images/svg/openEyeIcon.svg'
import InputMask from 'react-input-mask';
const InputMaskMoney = ({name,type,placeholder,onChange,width,error,height,marginLeft,color,money,value,rightEye,topEye,mask,disabled,multiple,fixed }) => {
    
    const formattedValue = value ? (value.replace(/\D/g, '') / 100).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") : '';

        
    const containerInput = {

        display:"flex",
        flexDirection:"column",
        marginLeft:marginLeft,
        width:width,
    }
    const styleInput = {
        width:width != undefined ? width :"418px",
        color:color != undefined ? color : "",
        height:height != undefined ? height : "50px",
        borderRadius:"10px",
        border:"1px solid #D7D7D7",
        marginBottom:"1.9rem",
        outline:"none",
        backgroundColor: disabled && "#f4f4f4"
    }
    const styleLabel = {
        marginBottom:"14.43px"
    }
    const containerInputMail = {
        width:width != undefined ? width :"418px",

        display:"flex",
        flexDirection:"column",
        position:"relative",

    }
    const containerInputPassowrd = {
        display:"flex",
        flexDirection:"column",
        position:"relative",
        width:width != undefined ? width :"418px",

    }
    const image = {

        position:"absolute",
        right:0,
        top:topEye != undefined ? topEye :"50px",
        right:rightEye != undefined ? rightEye :"10px",
        cursor:"pointer"
    }

    return(

        <div style={containerInput}>
    

        {type == "money" 
        
        ?
        <div style={containerInputMail}>
        <label className="textPrimaryLabelInput" style={styleLabel}>{name}</label>
            
        <InputMask  maskChar="" multiple={multiple} mask={mask} className="textPrimaryInput" value={formattedValue} onChange={onChange} placeholder={placeholder} type={type} style={styleInput} />
        {error != undefined 
        
        ?
        <p className="textErrorInput">{error}</p>
        :
        <></>
        }
        </div>
        :
        <></>
        }

        


        </div>


    )

}

export default InputMaskMoney