import React,{createContext,useState} from 'react'

import api from '../services/api'

export const FilterContext = createContext({})

function FilterProvider({children}){

    const [search,setSearch] = useState()
    const [dataFilter,setDataFilter] = useState()

    const getSearch = async (value) => {

        const response = await api.post("client/immobiles/by/code",{
            "code":value
        })

        if(response.status == 200){
            setDataFilter(response.data)
        }
        setSearch(value)        
    }


    
    return(
    <FilterContext.Provider value={{getSearch,search,dataFilter}}>
        {children}
    </FilterContext.Provider>
    )

}

export default FilterProvider