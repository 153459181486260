import React, { useEffect, useContext } from 'react'
import ThowColumLayoutInventary from '../../layouts/ThowColumLayoutInventary'
import Sidebar from '../../components/Sidebar'
import FilterManager from '../../components/FilterManager'
import TableListManager from '../../components/TableListManager'
import Modal from 'react-bootstrap/Modal';

import { ModalContext } from '../../context/modal'
import HeaderDashboard from '../../components/HeaderDashboard'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const ManageMent = () => {
    const { t } = useTranslation()

    const { modal, changeStateModal, removeCountages } = useContext(ModalContext)

    const [valuePx, setValuePx] = useState(2850)

    const containerSecondary = {

        marginTop: "1.625rem",
        display: "flex",
        flexDirection: "column",
        overFlowY: "auto",
        height: "100%",

        overFlowX: "hidden",
        alignItems: "center",


    }



    useEffect(() => {
        const div = document.getElementById("test")
        const div2 = document.getElementById("test2")


        div.addEventListener("scroll", () => {

            div2.scrollLeft = div.scrollLeft

        })


    }, []);

    return (

        <ThowColumLayoutInventary
            background="#F9F9F9"
            name="Gestão de Contagens"
            colum1={

                <Sidebar page="ManageMent" />

            }
            colum2={

                <div style={containerSecondary} >
                    <div style={{ width: "1020px", display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }} >

                        <div style={{ width: "1020px", display: "flex", flexDirection: "column", height: "100%" }}>
                            <HeaderDashboard name={t('countManagement')} />
                            <FilterManager />

                            <TableListManager page="5" valuePx={valuePx} setValuePx={setValuePx} />


                            <div>
                                <div id="test" style={{ width: "1020px", overflowX: "scroll", position: "fixed", height: "37px" }} className='scrolltest'>
                                    <p style={{ width: "3680px" }}></p>
                                </div>
                            </div>

                        </div>


                    </div>

                    <Modal
                        dialogClassName="modal-filter-table-list-user-edit"
                        show={modal}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header >

                            <div style={{ display: "flex", height: "30px", justifyContent: "space-between", width: "100%" }}>

                                <p className="textPrimaryModalFilterManager" style={{ position: "relative", right: "17px" }}>{t('actions')}</p>

                                <div onClick={() => changeStateModal()} style={{ cursor: "pointer" }}>
                                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_0_2887)">
                                            <path d="M7.49864 6.3679L12.5016 1.35941C12.8122 1.04847 12.8122 0.544354 12.5016 0.233445C12.1911 -0.0774893 11.6876 -0.0774893 11.377 0.233445L6.37456 5.24246L1.37211 0.233445C1.06155 -0.0774893 0.55804 -0.0774893 0.247506 0.233445C-0.0630274 0.544378 -0.0630522 1.0485 0.247506 1.35941L5.25048 6.3679L0.247506 11.3764C-0.0630522 11.6874 -0.0630522 12.1915 0.247506 12.5024C0.558065 12.8133 1.06158 12.8133 1.37211 12.5024L6.37456 7.49334L11.377 12.5024C11.6876 12.8133 12.1911 12.8133 12.5016 12.5024C12.8122 12.1915 12.8122 11.6873 12.5016 11.3764L7.49864 6.3679Z" fill="black" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_0_2887">
                                                <rect width="12.72" height="12.7354" fill="white" transform="translate(0.0143433)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>

                            </div>


                        </Modal.Header>
                        <Modal.Body style={{ padding: "0px" }}>

                            <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>

                                <div onClick={() => window.location.href = "/dashboard/edit/stock"} style={{ height: "55px", width: "100%", borderBottom: "1px solid #D7D7D7", textAlign: "center", paddingTop: "0.9rem", cursor: "pointer" }}>
                                    <p className="textModalTableListUserEditPrimary" >{t('editCount')}</p>
                                </div>

                                <div onClick={() => removeCountages()} style={{ height: "55px", width: "100%", borderBottom: "1px solid #D7D7D7", textAlign: "center", paddingTop: "0.9rem", cursor: "pointer" }}>
                                    <p className='textModalTableListUserEditSecondary'>{t('deleteCount')}</p>
                                </div>

                                <div style={{ height: "70px" }}></div>

                            </div>

                        </Modal.Body>



                    </Modal>

                </div>


            }
        >

        </ThowColumLayoutInventary>


    )

}

export default ManageMent