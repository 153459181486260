import React,{useEffect,useState} from 'react'
import {Navigate} from 'react-router-dom'
import api from '../../services/api'
import { Nav } from 'react-bootstrap'

const PrivateRoute =  ({ children,page}) =>{

    const [backEndPermission,setBackEndPermission] = useState()

    const getPermission = async () => {

        const response =  await api.post("session/storage/panel",{
            mail:localStorage.getItem("@USER_MAIL"),
            password:localStorage.getItem("@USER_PASSWORD")
        })


        if(response.status == 200){
            localStorage.setItem("@USER_PERMISSION",response.data.user.permission)
            setBackEndPermission(response.data.user.permission)
        }

    }

    useEffect(() => {

        getPermission()

    },[])

    const mail = localStorage.getItem("@USER_MAIL")

    if(!mail){
        return <Navigate to="/"></Navigate>
    }
    
    if(backEndPermission == "Administrativo" ){

        return mail != undefined ? children : <></>
    }

    if(backEndPermission == "Cliente" ){
    
        if( mail != undefined  && page === "dashboard" || page == "inventary"){
            
            return children
        }else{

            localStorage.removeItem("@USER_MAIL")
            window.location.href="/"
   
        }
    
    }


}

export default PrivateRoute