import React, { useState, useEffect } from 'react'
import companyIcon from '../../images/png/49fff0ec-610e-4b4a-bcfe-4d03f4bb43c3.png'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SelectLanguage from '../SelectLanguage';

const Sidebar = ({ page }) => {
    const navigate = useNavigate()
    const { t, i18n } = useTranslation()

    const container = {

        width: "265px",
        position: "fixed",
        top: "0",
        zIndex: "2",
        height: "100vh",
        background: "white",
        border: "1px solid #D7D7D7",
        borderTopRightRadius: 17,
        borderBottomRightRadius: 17
    }
    const containerImage = {

        display: "flex",
        justifyContent: "center",
        marginTop: "2.625rem"
    }
    const containerItem = {
        display: "flex",
        alignItems: "center",
        width: "200px",
        borderRadius: "8px",
        paddingTop: "2px",
        paddingBottom: "3px",
        height: "39px",
        background: "#123164",
        marginBottom: "21px",
        cursor: "pointer"

    }
    const containerItemNotChecked = {
        display: "flex",
        alignItems: "center",
        width: "243px",
        borderRadius: "8px",
        paddingTop: "2px",
        paddingBottom: "3px",
        height: "39px",
        background: "white",
        marginBottom: "21px",
        cursor: "pointer"

    }
    const image = {

        position: "relative",
        top: "1px",
        marginLeft: "11px"

    }

    const redirectToDashboardPage = () => {

        navigate("/dashboard")


    }
    const redirectToInventaryPage = () => {

        navigate("/dashboard/inventary")

    }
    const redirectToManagerPage = () => {

        navigate("/dashboard/add/stock")
    }

    const redirectToRequestPage = () => {
        navigate("/dashboard/requests/")

    }

    const redirectToUserPage = () => {

        navigate("/dashboard/users")

    }
    const redirectToExit = () => {
        localStorage.clear()
        navigate("/")
    }


    return (

        <div style={container}>
            <div style={containerImage}>
                <img src={companyIcon} style={{ width: "150px" }} />
            </div>

            <div style={{ paddingLeft: "12px", paddingRight: "12px", marginTop: "52px" }}>

                {localStorage.getItem("@USER_PERMISSION") == "Cliente"

                    ?
                    <>


                        <div onClick={() => redirectToInventaryPage()} style={page == "Inventary" ? containerItem : containerItemNotChecked}>

                            <div style={image}>

                                {page == "Inventary"

                                    ?
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19.5 16C19.5 16.553 19.053 17 18.5 17H16.5C15.947 17 15.5 16.553 15.5 16C15.5 15.447 15.947 15 16.5 15H18.5C19.053 15 19.5 15.447 19.5 16ZM24 15V20C24 22.206 22.206 24 20 24H4C1.794 24 0 22.206 0 20V15C0 12.794 1.794 11 4 11H5V4C5 1.794 6.794 0 9 0H15C17.206 0 19 1.794 19 4V11H20C22.206 11 24 12.794 24 15ZM7 11H17V4C17 2.897 16.103 2 15 2H9C7.897 2 7 2.897 7 4V11ZM4 22H11V13H4C2.897 13 2 13.897 2 15V20C2 21.103 2.897 22 4 22ZM22 15C22 13.897 21.103 13 20 13H13V22H20C21.103 22 22 21.103 22 20V15ZM7.5 15H5.5C4.947 15 4.5 15.447 4.5 16C4.5 16.553 4.947 17 5.5 17H7.5C8.053 17 8.5 16.553 8.5 16C8.5 15.447 8.053 15 7.5 15ZM14 5C14 4.447 13.553 4 13 4H11C10.447 4 10 4.447 10 5C10 5.553 10.447 6 11 6H13C13.553 6 14 5.553 14 5Z" fill="white" />
                                    </svg>

                                    :
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19.5 16C19.5 16.553 19.053 17 18.5 17H16.5C15.947 17 15.5 16.553 15.5 16C15.5 15.447 15.947 15 16.5 15H18.5C19.053 15 19.5 15.447 19.5 16ZM24 15V20C24 22.206 22.206 24 20 24H4C1.794 24 0 22.206 0 20V15C0 12.794 1.794 11 4 11H5V4C5 1.794 6.794 0 9 0H15C17.206 0 19 1.794 19 4V11H20C22.206 11 24 12.794 24 15ZM7 11H17V4C17 2.897 16.103 2 15 2H9C7.897 2 7 2.897 7 4V11ZM4 22H11V13H4C2.897 13 2 13.897 2 15V20C2 21.103 2.897 22 4 22ZM22 15C22 13.897 21.103 13 20 13H13V22H20C21.103 22 22 21.103 22 20V15ZM7.5 15H5.5C4.947 15 4.5 15.447 4.5 16C4.5 16.553 4.947 17 5.5 17H7.5C8.053 17 8.5 16.553 8.5 16C8.5 15.447 8.053 15 7.5 15ZM14 5C14 4.447 13.553 4 13 4H11C10.447 4 10 4.447 10 5C10 5.553 10.447 6 11 6H13C13.553 6 14 5.553 14 5Z" fill="#123164" />
                                    </svg>

                                }

                            </div>

                            {page == "Inventary"

                                ?

                                <p className="textCheckedSidebar">Imóveis</p>
                                :
                                <p className="textNotCheckedSidebar">Imóveis</p>

                            }


                        </div>

                        <div onClick={() => redirectToExit()} style={{ ...containerItemNotChecked, marginTop: "50px", marginBottom: "50px" }}>

                            <div style={image}>

                                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.10249 16.5727H3.94583C3.52723 16.5727 3.12577 16.4064 2.82978 16.1104C2.53378 15.8144 2.36749 15.4129 2.36749 14.9943V3.94601C2.36749 3.52741 2.53378 3.12595 2.82978 2.82996C3.12577 2.53396 3.52723 2.36768 3.94583 2.36768H7.10249" stroke="#123164" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M12.6266 13.4156L16.5725 9.46976L12.6266 5.52393" stroke="#123164" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M16.5725 9.47021H7.10248" stroke="#123164" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>



                            <p style={{ marginBottom: "8px" }} className="textNotCheckedSidebar">{t('exit')}</p>



                        </div>

                    </>
                    :

                    <>




                        <div onClick={() => redirectToInventaryPage()} style={page == "Inventary" ? containerItem : containerItemNotChecked}>

                            <div style={image}>

                                {page == "Inventary"

                                    ?
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19.5 16C19.5 16.553 19.053 17 18.5 17H16.5C15.947 17 15.5 16.553 15.5 16C15.5 15.447 15.947 15 16.5 15H18.5C19.053 15 19.5 15.447 19.5 16ZM24 15V20C24 22.206 22.206 24 20 24H4C1.794 24 0 22.206 0 20V15C0 12.794 1.794 11 4 11H5V4C5 1.794 6.794 0 9 0H15C17.206 0 19 1.794 19 4V11H20C22.206 11 24 12.794 24 15ZM7 11H17V4C17 2.897 16.103 2 15 2H9C7.897 2 7 2.897 7 4V11ZM4 22H11V13H4C2.897 13 2 13.897 2 15V20C2 21.103 2.897 22 4 22ZM22 15C22 13.897 21.103 13 20 13H13V22H20C21.103 22 22 21.103 22 20V15ZM7.5 15H5.5C4.947 15 4.5 15.447 4.5 16C4.5 16.553 4.947 17 5.5 17H7.5C8.053 17 8.5 16.553 8.5 16C8.5 15.447 8.053 15 7.5 15ZM14 5C14 4.447 13.553 4 13 4H11C10.447 4 10 4.447 10 5C10 5.553 10.447 6 11 6H13C13.553 6 14 5.553 14 5Z" fill="white" />
                                    </svg>

                                    :
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19.5 16C19.5 16.553 19.053 17 18.5 17H16.5C15.947 17 15.5 16.553 15.5 16C15.5 15.447 15.947 15 16.5 15H18.5C19.053 15 19.5 15.447 19.5 16ZM24 15V20C24 22.206 22.206 24 20 24H4C1.794 24 0 22.206 0 20V15C0 12.794 1.794 11 4 11H5V4C5 1.794 6.794 0 9 0H15C17.206 0 19 1.794 19 4V11H20C22.206 11 24 12.794 24 15ZM7 11H17V4C17 2.897 16.103 2 15 2H9C7.897 2 7 2.897 7 4V11ZM4 22H11V13H4C2.897 13 2 13.897 2 15V20C2 21.103 2.897 22 4 22ZM22 15C22 13.897 21.103 13 20 13H13V22H20C21.103 22 22 21.103 22 20V15ZM7.5 15H5.5C4.947 15 4.5 15.447 4.5 16C4.5 16.553 4.947 17 5.5 17H7.5C8.053 17 8.5 16.553 8.5 16C8.5 15.447 8.053 15 7.5 15ZM14 5C14 4.447 13.553 4 13 4H11C10.447 4 10 4.447 10 5C10 5.553 10.447 6 11 6H13C13.553 6 14 5.553 14 5Z" fill="#123164" />
                                    </svg>

                                }

                            </div>

                            {page == "Inventary"

                                ?

                                <p className="textCheckedSidebar">{t('Imovéis')}</p>
                                :
                                <p className="textNotCheckedSidebar">{t('Imovéis')}</p>

                            }


                        </div>


                        <div onClick={() => redirectToManagerPage()} style={page == "ManageMent" || page == "addStock" ? containerItem : containerItemNotChecked}>

                            <div style={image}>

                                {page == "ManageMent" || page == "addStock"

                                    ?
                                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M20.922 5.82288C20.7251 5.3819 20.208 5.18416 19.7662 5.38278L19.2491 5.61464V0.874964C19.2491 0.391984 18.858 0 18.3741 0C17.8903 0 17.4991 0.391984 17.4991 0.874964V6.39948L13.9993 7.97004V0.874964C13.9993 0.391984 13.6082 0 13.1243 0C12.6405 0 12.2494 0.391984 12.2494 0.874964V8.75489L8.74951 10.3254V0.874964C8.74951 0.391984 8.35753 0 7.87455 0C7.39157 0 6.99958 0.391984 6.99958 0.874964V11.1103L3.49973 12.6808V0.874964C3.49973 0.391984 3.10775 0 2.62477 0C2.14179 0 1.7498 0.391984 1.7498 0.874964V13.4657L0.516979 14.0187C0.0759977 14.2164 -0.120869 14.7335 0.0768726 15.1745C0.222117 15.4991 0.541478 15.6916 0.875714 15.6916C0.995584 15.6916 1.1172 15.6671 1.23357 15.6146L1.75068 15.3827V20.1233C1.75068 20.6071 2.14266 20.9983 2.62564 20.9983C3.10862 20.9983 3.5006 20.6071 3.5006 20.1233V14.597L7.00046 13.0273V20.1233C7.00046 20.6071 7.39244 20.9983 7.87542 20.9983C8.3584 20.9983 8.75039 20.6071 8.75039 20.1233V12.2434L12.2502 10.6737V20.125C12.2502 20.6089 12.6413 21 13.1252 21C13.6091 21 14.0002 20.6089 14.0002 20.125V9.88796L17.5 8.31828V20.125C17.5 20.6089 17.8911 21 18.375 21C18.8588 21 19.2499 20.6089 19.2499 20.125V7.53256L20.4828 6.97958C20.9238 6.78184 21.1197 6.26386 20.922 5.82288Z" fill="white" />
                                    </svg>

                                    :
                                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M20.922 5.82288C20.7251 5.3819 20.208 5.18416 19.7662 5.38278L19.2491 5.61464V0.874964C19.2491 0.391984 18.858 0 18.3741 0C17.8903 0 17.4991 0.391984 17.4991 0.874964V6.39948L13.9993 7.97004V0.874964C13.9993 0.391984 13.6082 0 13.1243 0C12.6405 0 12.2494 0.391984 12.2494 0.874964V8.75489L8.74951 10.3254V0.874964C8.74951 0.391984 8.35753 0 7.87455 0C7.39157 0 6.99958 0.391984 6.99958 0.874964V11.1103L3.49973 12.6808V0.874964C3.49973 0.391984 3.10775 0 2.62477 0C2.14179 0 1.7498 0.391984 1.7498 0.874964V13.4657L0.516979 14.0187C0.0759977 14.2164 -0.120869 14.7335 0.0768726 15.1745C0.222117 15.4991 0.541478 15.6916 0.875714 15.6916C0.995584 15.6916 1.1172 15.6671 1.23357 15.6146L1.75068 15.3827V20.1233C1.75068 20.6071 2.14266 20.9983 2.62564 20.9983C3.10862 20.9983 3.5006 20.6071 3.5006 20.1233V14.597L7.00046 13.0273V20.1233C7.00046 20.6071 7.39244 20.9983 7.87542 20.9983C8.3584 20.9983 8.75039 20.6071 8.75039 20.1233V12.2434L12.2502 10.6737V20.125C12.2502 20.6089 12.6413 21 13.1252 21C13.6091 21 14.0002 20.6089 14.0002 20.125V9.88796L17.5 8.31828V20.125C17.5 20.6089 17.8911 21 18.375 21C18.8588 21 19.2499 20.6089 19.2499 20.125V7.53256L20.4828 6.97958C20.9238 6.78184 21.1197 6.26386 20.922 5.82288Z" fill="#123164" />
                                    </svg>

                                }
                            </div>

                            {page == "ManageMent" || page == "addStock"

                                ?
                                <p className="textCheckedSidebar">{t('Adicionar Imóvel')}</p>

                                :

                                <p className="textNotCheckedSidebar">{t('Adicionar Imóvel')}</p>
                            }


                        </div>

                        <div onClick={() => redirectToRequestPage()} style={page == "Request" ? containerItem : containerItemNotChecked}>

                            <div style={image}>

                                {page == "Request"

                                    ?


                                    <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.31 7.81382L8.32689 6.81719V2.58986C8.33476 1.98721 8.13036 1.401 7.74948 0.933894C7.36861 0.466789 6.83554 0.148569 6.24364 0.0349655C5.88511 -0.0256377 5.51767 -0.00729743 5.16696 0.088707C4.81624 0.184711 4.49069 0.356069 4.21301 0.590828C3.93533 0.825587 3.7122 1.1181 3.55919 1.44796C3.40618 1.77781 3.32698 2.13708 3.32709 2.5007V8.05131L1.00302 11.0837C0.321372 11.8815 -0.0356224 12.9062 0.00280962 13.9549C0.0412416 15.0035 0.472294 15.9994 1.21051 16.7451L3.21043 18.7659C3.99518 19.5533 5.0603 19.9971 6.17198 20H12.4934C13.598 19.9987 14.657 19.5593 15.4381 18.7782C16.2192 17.9971 16.6586 16.9381 16.6599 15.8335V11.8995C16.6578 10.9368 16.3233 10.0043 15.7129 9.2598C15.1025 8.51532 14.2537 8.00452 13.31 7.81382ZM14.9933 15.8335C14.9933 16.4965 14.7299 17.1324 14.2611 17.6012C13.7923 18.07 13.1564 18.3334 12.4934 18.3334H6.17198C5.50423 18.3315 4.86459 18.0644 4.39372 17.5909L2.3938 15.5677C1.94691 15.113 1.68857 14.5061 1.67064 13.8688C1.65271 13.2315 1.87652 12.6111 2.29713 12.132L3.32709 10.7879V14.1669C3.32709 14.3879 3.41489 14.5999 3.57116 14.7561C3.72743 14.9124 3.93939 15.0002 4.16039 15.0002C4.3814 15.0002 4.59335 14.9124 4.74962 14.7561C4.9059 14.5999 4.99369 14.3879 4.99369 14.1669V2.5007C4.99362 2.37881 5.02028 2.25838 5.07181 2.14792C5.12334 2.03745 5.19848 1.93964 5.29192 1.86137C5.38537 1.7831 5.49485 1.72629 5.61264 1.69493C5.73043 1.66358 5.85366 1.65845 5.97365 1.6799C6.1754 1.72928 6.35392 1.8468 6.47903 2.0126C6.60414 2.1784 6.66817 2.38231 6.66029 2.58986V7.5005C6.66025 7.6926 6.72659 7.87882 6.84807 8.02763C6.96955 8.17644 7.13871 8.27871 7.32693 8.31713L12.98 9.44792C13.5467 9.56199 14.0566 9.86829 14.4235 10.315C14.7903 10.7617 14.9916 11.3214 14.9933 11.8995V15.8335Z" fill="white" />
                                    </svg>

                                    :


                                    <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.31 7.81382L8.32689 6.81719V2.58986C8.33476 1.98721 8.13036 1.401 7.74948 0.933894C7.36861 0.466789 6.83554 0.148569 6.24364 0.0349655C5.88511 -0.0256377 5.51767 -0.00729743 5.16696 0.088707C4.81624 0.184711 4.49069 0.356069 4.21301 0.590828C3.93533 0.825587 3.7122 1.1181 3.55919 1.44796C3.40618 1.77781 3.32698 2.13708 3.32709 2.5007V8.05131L1.00302 11.0837C0.321372 11.8815 -0.0356224 12.9062 0.00280962 13.9549C0.0412416 15.0035 0.472294 15.9994 1.21051 16.7451L3.21043 18.7659C3.99518 19.5533 5.0603 19.9971 6.17198 20H12.4934C13.598 19.9987 14.657 19.5593 15.4381 18.7782C16.2192 17.9971 16.6586 16.9381 16.6599 15.8335V11.8995C16.6578 10.9368 16.3233 10.0043 15.7129 9.2598C15.1025 8.51532 14.2537 8.00452 13.31 7.81382ZM14.9933 15.8335C14.9933 16.4965 14.7299 17.1324 14.2611 17.6012C13.7923 18.07 13.1564 18.3334 12.4934 18.3334H6.17198C5.50423 18.3315 4.86459 18.0644 4.39372 17.5909L2.3938 15.5677C1.94691 15.113 1.68857 14.5061 1.67064 13.8688C1.65271 13.2315 1.87652 12.6111 2.29713 12.132L3.32709 10.7879V14.1669C3.32709 14.3879 3.41489 14.5999 3.57116 14.7561C3.72743 14.9124 3.93939 15.0002 4.16039 15.0002C4.3814 15.0002 4.59335 14.9124 4.74962 14.7561C4.9059 14.5999 4.99369 14.3879 4.99369 14.1669V2.5007C4.99362 2.37881 5.02028 2.25838 5.07181 2.14792C5.12334 2.03745 5.19848 1.93964 5.29192 1.86137C5.38537 1.7831 5.49485 1.72629 5.61264 1.69493C5.73043 1.66358 5.85366 1.65845 5.97365 1.6799C6.1754 1.72928 6.35392 1.8468 6.47903 2.0126C6.60414 2.1784 6.66817 2.38231 6.66029 2.58986V7.5005C6.66025 7.6926 6.72659 7.87882 6.84807 8.02763C6.96955 8.17644 7.13871 8.27871 7.32693 8.31713L12.98 9.44792C13.5467 9.56199 14.0566 9.86829 14.4235 10.315C14.7903 10.7617 14.9916 11.3214 14.9933 11.8995V15.8335Z" fill="#1B3063" />
                                    </svg>


                                }
                            </div>

                            {page == "Request"

                                ?
                                <p className="textCheckedSidebar">{t('Solicitações')}</p>

                                :

                                <p className="textNotCheckedSidebar">{t('Solicitações')}</p>
                            }


                        </div>
                        <div onClick={() => redirectToUserPage()} style={page == "Users" ? containerItem : containerItemNotChecked}>

                            <div style={image}>
                                {page == "Users"

                                    ?
                                    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_110_3473)">
                                            <path d="M22.0416 10.5416H20.1249V8.62496C20.1249 8.37079 20.024 8.12704 19.8442 7.94732C19.6645 7.76759 19.4208 7.66663 19.1666 7.66663C18.9124 7.66663 18.6687 7.76759 18.4889 7.94732C18.3092 8.12704 18.2083 8.37079 18.2083 8.62496V10.5416H16.2916C16.0374 10.5416 15.7937 10.6426 15.6139 10.8223C15.4342 11.002 15.3333 11.2458 15.3333 11.5C15.3333 11.7541 15.4342 11.9979 15.6139 12.1776C15.7937 12.3573 16.0374 12.4583 16.2916 12.4583H18.2083V14.375C18.2083 14.6291 18.3092 14.8729 18.4889 15.0526C18.6687 15.2323 18.9124 15.3333 19.1666 15.3333C19.4208 15.3333 19.6645 15.2323 19.8442 15.0526C20.024 14.8729 20.1249 14.6291 20.1249 14.375V12.4583H22.0416C22.2958 12.4583 22.5395 12.3573 22.7192 12.1776C22.899 11.9979 22.9999 11.7541 22.9999 11.5C22.9999 11.2458 22.899 11.002 22.7192 10.8223C22.5395 10.6426 22.2958 10.5416 22.0416 10.5416Z" fill="white" />
                                            <path d="M8.625 11.5C9.76224 11.5 10.8739 11.1628 11.8195 10.5309C12.7651 9.89913 13.5021 9.0011 13.9373 7.95043C14.3725 6.89976 14.4864 5.74362 14.2645 4.62823C14.0426 3.51284 13.495 2.48829 12.6909 1.68414C11.8867 0.879986 10.8622 0.332352 9.74677 0.110487C8.63138 -0.111378 7.47524 0.00249139 6.42457 0.437695C5.37389 0.872899 4.47587 1.60989 3.84405 2.55547C3.21223 3.50106 2.875 4.61276 2.875 5.75C2.87652 7.27453 3.48281 8.73618 4.56082 9.81418C5.63882 10.8922 7.10047 11.4985 8.625 11.5ZM8.625 1.91667C9.38316 1.91667 10.1243 2.14149 10.7547 2.5627C11.3851 2.98391 11.8764 3.5826 12.1665 4.28305C12.4567 4.9835 12.5326 5.75425 12.3847 6.49785C12.2368 7.24144 11.8717 7.92448 11.3356 8.46058C10.7995 8.99668 10.1164 9.36177 9.37284 9.50968C8.62925 9.65759 7.85849 9.58167 7.15804 9.29154C6.45759 9.0014 5.85891 8.51007 5.4377 7.87969C5.01649 7.2493 4.79167 6.50816 4.79167 5.75C4.79167 4.73334 5.19553 3.75831 5.91442 3.03943C6.63331 2.32054 7.60833 1.91667 8.625 1.91667V1.91667Z" fill="white" />
                                            <path d="M8.625 13.4166C6.33828 13.4192 4.14596 14.3287 2.529 15.9456C0.912053 17.5626 0.00253657 19.7549 0 22.0416C0 22.2958 0.100967 22.5395 0.280689 22.7193C0.460411 22.899 0.704167 23 0.958333 23C1.2125 23 1.45625 22.899 1.63598 22.7193C1.8157 22.5395 1.91667 22.2958 1.91667 22.0416C1.91667 20.2625 2.62343 18.5562 3.88149 17.2981C5.13955 16.0401 6.84584 15.3333 8.625 15.3333C10.4042 15.3333 12.1104 16.0401 13.3685 17.2981C14.6266 18.5562 15.3333 20.2625 15.3333 22.0416C15.3333 22.2958 15.4343 22.5395 15.614 22.7193C15.7937 22.899 16.0375 23 16.2917 23C16.5458 23 16.7896 22.899 16.9693 22.7193C17.149 22.5395 17.25 22.2958 17.25 22.0416C17.2475 19.7549 16.3379 17.5626 14.721 15.9456C13.104 14.3287 10.9117 13.4192 8.625 13.4166V13.4166Z" fill="white" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_110_3473">
                                                <rect width="23" height="23" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>

                                    :

                                    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_0_133)">
                                            <path d="M22.0416 10.5416H20.1249V8.62496C20.1249 8.37079 20.024 8.12704 19.8442 7.94732C19.6645 7.76759 19.4208 7.66663 19.1666 7.66663C18.9124 7.66663 18.6687 7.76759 18.4889 7.94732C18.3092 8.12704 18.2083 8.37079 18.2083 8.62496V10.5416H16.2916C16.0374 10.5416 15.7937 10.6426 15.6139 10.8223C15.4342 11.002 15.3333 11.2458 15.3333 11.5C15.3333 11.7541 15.4342 11.9979 15.6139 12.1776C15.7937 12.3573 16.0374 12.4583 16.2916 12.4583H18.2083V14.375C18.2083 14.6291 18.3092 14.8729 18.4889 15.0526C18.6687 15.2323 18.9124 15.3333 19.1666 15.3333C19.4208 15.3333 19.6645 15.2323 19.8442 15.0526C20.024 14.8729 20.1249 14.6291 20.1249 14.375V12.4583H22.0416C22.2958 12.4583 22.5395 12.3573 22.7192 12.1776C22.899 11.9979 22.9999 11.7541 22.9999 11.5C22.9999 11.2458 22.899 11.002 22.7192 10.8223C22.5395 10.6426 22.2958 10.5416 22.0416 10.5416Z" fill="#123164" />
                                            <path d="M8.625 11.5C9.76224 11.5 10.8739 11.1628 11.8195 10.5309C12.7651 9.89913 13.5021 9.0011 13.9373 7.95043C14.3725 6.89976 14.4864 5.74362 14.2645 4.62823C14.0426 3.51284 13.495 2.48829 12.6909 1.68414C11.8867 0.879986 10.8622 0.332352 9.74677 0.110487C8.63137 -0.111378 7.47524 0.00249139 6.42457 0.437695C5.37389 0.872899 4.47587 1.60989 3.84405 2.55547C3.21223 3.50106 2.875 4.61276 2.875 5.75C2.87652 7.27453 3.48281 8.73618 4.56082 9.81418C5.63882 10.8922 7.10047 11.4985 8.625 11.5ZM8.625 1.91667C9.38316 1.91667 10.1243 2.14149 10.7547 2.5627C11.3851 2.98391 11.8764 3.5826 12.1665 4.28305C12.4567 4.9835 12.5326 5.75425 12.3847 6.49785C12.2368 7.24144 11.8717 7.92448 11.3356 8.46058C10.7995 8.99668 10.1164 9.36177 9.37284 9.50968C8.62925 9.65759 7.85849 9.58167 7.15804 9.29154C6.45759 9.0014 5.85891 8.51007 5.4377 7.87969C5.01649 7.2493 4.79167 6.50816 4.79167 5.75C4.79167 4.73334 5.19553 3.75831 5.91442 3.03943C6.63331 2.32054 7.60833 1.91667 8.625 1.91667Z" fill="#123164" />
                                            <path d="M8.625 13.4166C6.33828 13.4192 4.14596 14.3287 2.529 15.9456C0.912053 17.5626 0.00253657 19.7549 0 22.0416C0 22.2958 0.100967 22.5395 0.280689 22.7193C0.460411 22.899 0.704167 23 0.958333 23C1.2125 23 1.45625 22.899 1.63598 22.7193C1.8157 22.5395 1.91667 22.2958 1.91667 22.0416C1.91667 20.2625 2.62343 18.5562 3.88149 17.2981C5.13955 16.0401 6.84584 15.3333 8.625 15.3333C10.4042 15.3333 12.1104 16.0401 13.3685 17.2981C14.6266 18.5562 15.3333 20.2625 15.3333 22.0416C15.3333 22.2958 15.4343 22.5395 15.614 22.7193C15.7937 22.899 16.0375 23 16.2917 23C16.5458 23 16.7896 22.899 16.9693 22.7193C17.149 22.5395 17.25 22.2958 17.25 22.0416C17.2475 19.7549 16.3379 17.5626 14.721 15.9456C13.104 14.3287 10.9117 13.4192 8.625 13.4166Z" fill="#123164" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_0_133">
                                                <rect width="23" height="23" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>

                                }

                            </div>


                            {page == "Users"

                                ?
                                <p className="textCheckedSidebar">{t('manageAccess')}</p>
                                :
                                <p className="textNotCheckedSidebar">{t('manageAccess')}</p>

                            }


                        </div>

                        <div onClick={() => redirectToExit()} style={{ ...containerItemNotChecked, marginTop: "50px", marginBottom: "50px" }}>

                            <div style={image}>

                                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.10249 16.5727H3.94583C3.52723 16.5727 3.12577 16.4064 2.82978 16.1104C2.53378 15.8144 2.36749 15.4129 2.36749 14.9943V3.94601C2.36749 3.52741 2.53378 3.12595 2.82978 2.82996C3.12577 2.53396 3.52723 2.36768 3.94583 2.36768H7.10249" stroke="#123164" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M12.6266 13.4156L16.5725 9.46976L12.6266 5.52393" stroke="#123164" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M16.5725 9.47021H7.10248" stroke="#123164" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>



                            <p style={{ marginBottom: "8px" }} className="textNotCheckedSidebar">{t('exit')}</p>



                        </div>

                    </>

                }




            </div>
        </div>

    )

}

export default Sidebar