import React, { useState, useEffect } from 'react'
import { BigHead } from '@bigheads/core'
import { Tooltip as ReactTooltip } from "react-tooltip";
import api from '../../services/api'
const InputData = ({ id, client, onClick, name,goal,location,type,description,bathroom,district,iptu,m2,code,price,id_prop}) => {

    const [usersInCountage, setUserInCountage] = useState([])
    const [image,setImage] = useState()
    const [addresses,setAddresses] = useState()

    const getUsersInCountage = async () => {

        const response = await api.post("user/countages", {
            "client": client,
        })

        //setUserInCountage(response.data)

        const newArray = response.data.filter(item => item.id_countage == id)

        setUserInCountage(newArray)

        console.log(newArray)

    }
    const getImage = async () => {
        const response = await api.post("client/immobiles/image/", {
            "id_prop": id_prop
        })

        setImage(response.data[0].image)
    }

    const getAddresses = async () => {
        const response = await api.get(`client/immobiles/addresses/${id_prop}`)
        setAddresses(response.data[0].city)
    }


    useEffect(() => {
        getUsersInCountage()
    }, [])

    useEffect(() => {
        getImage()
        getAddresses()
    },[id_prop])

    return (

        <div style={{ minWidth: "1925px", width: usersInCountage.length == 1 || usersInCountage.length == 0 ? "190px" : "auto", height: "48px", border: "1px solid #D7D7D7", borderRadius: "7px", cursor: "pointer",background:"white",marginBottom:"2rem" }}>

            <div style={{ display: "flex", alignItems: "center", width: "100%" }}>


                <div data-tooltip-place='top' data-tooltip-position-strategy='fixed' data-tooltip-id="name" style={{ display: "flex", alignItems: "center", height: "35px", marginTop: "0.3rem", marginLeft: "0.5rem", marginRight: "0.5rem",gap: "5px", padding: "0 0 0 12px", borderRadius: "60px" }}>
                    <ReactTooltip id='name' />
                    <p className='textCardUserTable' style={{ transform: "translateY(8px)" }}></p>
                    <div style={{ transform: "translateY(-2px)" }}>


                        <BigHead
                            width={37}
                            accessory="shades"
                            body="chest"
                            circleColor="blue"
                            clothing="tankTop"
                            clothingColor="black"
                            eyebrows="angry"
                            eyes="wink"
                            facialHair="mediumBeard"
                            graphic="vue"
                            hair="short"
                            hairColor="black"
                            hat="none"
                            hatColor="green"
                            lashes="false"
                            lipColor="purple"
                            mask="true"
                            faceMask="true"
                            mouth="open"
                            skinTone="brown"
                        />



                    </div>


                    <p className="textNameTableListManager" style={{ marginLeft: "0.5rem" }}>{name}</p>
                    <p className="textNameTableListManager" style={{ marginLeft: "0.5rem" }}>{goal}</p>
                    <p className="textNameTableListManager" style={{ marginLeft: "0.5rem" }}>{addresses}</p>
                    <p className="textNameTableListManager" style={{ marginLeft: "0.5rem" }}>{type}</p>
                    <p className="textNameTableListManager" style={{ marginLeft: "0.5rem" }}>{description}</p>
                    <p className="textNameTableListManager" style={{ marginLeft: "0.5rem" }}>{bathroom}</p>
                    <p className="textNameTableListManager" style={{ marginLeft: "0.5rem" }}>{district}</p>
                    <p className="textNameTableListManager" style={{ marginLeft: "0.5rem" }}>R${iptu}</p>
                    <p className="textNameTableListManager" style={{ marginLeft: "0.5rem" }}>{m2} m²</p>
                    <p className="textNameTableListManager" style={{ marginLeft: "0.5rem" }}>
                    <img src={image} style={{width:"46px",height:"46px",borderRadius:"5px"}}/>    
                    </p>
                    <p className="textNameTableListManager" style={{ marginLeft: "0.5rem" }}>{Number(price).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                    <p className="textNameTableListManager" style={{ marginLeft: "0.5rem" }}>{code}</p>





                </div>



                <div onClick={onClick} style={{ width: "100%", height: "44px" }}>

                </div>
            </div>
        </div>

    )

}

export default InputData