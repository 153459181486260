import React, { createContext, useState, useEffect } from 'react'
import api from '../services/api'

export const ClientContext = createContext({})
function ClientProvider({ children }) {

    const [client, setClient] = useState()
    const [dataClient, setDataClient] = useState()

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const [typeClientOrCount, setTypeClientOrCount] = useState('client')
    const [clientInput, setClientInput] = useState('')
    const [typeGet, setTypeGet] = useState([])
    const [totalCounts, setTotalCounts] = useState(0);

    const [clientType, setClientType] = useState("")
    const [deleteSelect, setDeleteSelect] = useState(false)
    const [idsDelete, setIdsDelete] = useState([])

    const getClient = async (value, type) => {

        if (type == "inventary") {

            const response = await api.get(`countage/by/invetary/${value}`)

            if (response.status == 404) {
                setDataClient([])
                return false
            }
            setDataClient(response.data)
            return false

        }

        const response = await api.get(`countage/by/client/${value}?page=1`)

        if (response.status == 404) {
            setDataClient([])
            setTotalCounts(0)
            return false
        }
        
        setDataClient(response.data.data)
        setTotalCounts(response.data.totalCount)
    }

    const getTypeCountage = async (client, type) => {
        const response = await api.get(`countage/by/client/${client}/${type}?page=1`)

        if (response.status == 404) {
            setDataClient([])
            setTotalCounts(0)

            return false
        }
        setDataClient(response.data.data)
        setTotalCounts(response.data.totalCount)
    }

    const getTypeCountageLocation = async (client, type) => {
        const response = await api.get(`countage/by/client/${client}/location/${type}?page=1`)

        if (response.status == 404) {
            setDataClient([])
            setTotalCounts(0)

            return false
        }
        setDataClient(response.data.data)
        setTotalCounts(response.data.totalCount)
    }

    const getClientInventary = async (value, type) => {

        const response = await api.get(`countage/by/inventary/${value}?page=1`)
        console.log(value)

        if (response.status == 404) {
            setDataClient([])
            setTotalCounts(0)
            return false
        }
        setDataClient(response.data.data)
        setTotalCounts(response.data.totalCount)
    }

    const getTypeCountageInventary = async (client, type) => {
        const response = await api.get(`countage/by/inventary/${client}/${type}?page=1`)

        if (response.status == 404) {
            setDataClient([])
            setTotalCounts(0)

            return false
        }
        setDataClient(response.data.data)
        setTotalCounts(response.data.totalCount)
    }

    const getTypeCountageLocationInventary = async (client, type) => {
        const response = await api.get(`countage/by/inventary/${client}/location/${type}?page=1`)

        if (response.status == 404) {
            setDataClient([])
            setTotalCounts(0)

            return false
        }
        setDataClient(response.data.data)
        setTotalCounts(response.data.totalCount)
    }


    return (
        <ClientContext.Provider value={{
            getClient,
            client,
            dataClient,
            setDataClient,
            getTypeCountage,
            typeClientOrCount,
            setTypeClientOrCount,
            clientInput,
            setClientInput,
            typeGet,
            setTypeGet,
            clientType,
            setClientType,
            totalCounts,
            setTotalCounts,
            getTypeCountageLocation,
            getClientInventary,
            getTypeCountageInventary,
            getTypeCountageLocationInventary,

            deleteSelect,
            setDeleteSelect,
            idsDelete,
            setIdsDelete
        }}>
            {children}
        </ClientContext.Provider>
    )

}

export default ClientProvider