import React, { useState } from 'react'
import closeEyeIcon from '../../images/svg/closeEyeIcon.svg'
import openEyeIcon from '../../images/svg/openEyeIcon.svg'
import imageIcon from '../../images/png/image.png'
import InputMask from 'react-input-mask';
import Autocomplete from "react-google-autocomplete";

const Input = ({className, name, type, placeholder, onChange,onBlur, width, error, height, marginLeft, color, money, value, rightEye, topEye, mask, disabled, multiple,style }) => {


    const [stateEye, setStateEye] = useState(false)

    const containerInput = {

        display: "flex",
        flexDirection: "column",
        marginLeft: marginLeft,
        width: width,
    }
    const styleInput = {

        width: width != undefined ? width : "418px",
        color: color != undefined ? color : "",
        height: height != undefined ? height : "50px",
        borderRadius: "10px",
        opacity:  type == "file" ? 0 :"",
        border: "1px solid #D7D7D7",
        marginBottom: "1.9rem",
        outline: "none",
        appearance :"none",
        backgroundColor: disabled && "#f4f4f4"
    }
    const styleLabel = {
        marginBottom: "14.43px"
    }
    const containerInputMail = {
        width: width != undefined ? width : "418px",

        display: "flex",
        flexDirection: "column",
        position: "relative",

    }
    const containerInputPassowrd = {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        width: width != undefined ? width : "418px",

    }
    const image = {

        position: "absolute",
        right: 0,
        top: topEye != undefined ? topEye : "50px",
        right: rightEye != undefined ? rightEye : "10px",
        cursor: "pointer"
    }

    return (

        <div style={containerInput}>

            {type == "password"

                ?
                <div style={containerInputPassowrd}>
                    <label className="textPrimaryLabelInput" style={styleLabel}>{name}</label>
                    <input className="textPrimaryInput" onChange={onChange} placeholder={placeholder} type={stateEye == true ? "text" : type} style={styleInput} />

                    {stateEye == false

                        ?
                        <img src={closeEyeIcon} onClick={() => setStateEye(!stateEye)} style={image} />
                        :
                        <img src={openEyeIcon} onClick={() => setStateEye(!stateEye)} style={image} />

                    }

                    {error != undefined

                        ?
                        <p className="textErrorInput">{error}</p>
                        :
                        <></>
                    }
                </div>
                :

                <div style={containerInputMail}>
                    <label className="textPrimaryLabelInput" style={styleLabel}>{name}</label>

    
                    {type == "file" 
                    ?
                
                    <>
                    <label htmlFor='file' style={{zIndex:"3"}}>
                    <div   style={{border:"1px solid #D7D7D7",width:"167.4px",height:"45px",borderRadius:"10px",position:"relative"}}></div>
                    </label>
                    <img src={imageIcon}  style={{position:"absolute",width:"25px",top:"46px",left:"65px"}}/>
                    <input onBlur={onBlur}  onChange={onChange} type='file'id='file' style={{display:"none"}}/>
                    </>
                    :

                    <></>
                    }

                
                    <InputMask  onBlur={onBlur} disabled={disabled} maskChar="" multiple={multiple} mask={mask} className="textPrimaryInput" value={value} onChange={onChange} placeholder={placeholder} type={type} style={styleInput} />

                    {error != undefined

                        ?
                        <p className="textErrorInput">{error}</p>
                        :
                        <></>
                    }
                </div>
            }

    

        </div>


    )

}

export default Input