import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BigHead } from '@bigheads/core'
import api from '../../services/api';
import { Tooltip as ReactTooltip } from "react-tooltip";

const InputSearchUserCount = ({ id, name, mail, client, onBlur, idEdit, onClick }) => {

    const [stateInput, setStateInput] = useState(false)
    const [users, setUsers] = useState([])
    const [usersInCountage, setUserInCountage] = useState([])
    const [userCountage, setUserContage] = useState(false)

    const closeInput = () => {
        setTimeout(() => {
            onClick()
        }, 500);
    }

    const getUsers = async (name) => {

        if (name != undefined) {

            const response = await api.post('users/operation', {
                name: name
            })

            if (response.status == 404) {

                setUsers([])
                return false
            }

            setUsers(response.data)
        }

    }

    const addUserInCountage = async (name, mail) => {
        
       await api.post("/user/countage/participants", {
            name: name,
            mail: mail
        })

        /*toast.error('Usuário já existe na contagem', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });*/

        onClick(false)


    }

    const getParticipants = async () => {

    }

    useEffect(() => {

        getUsers()
        getParticipants()
    }, [])

    return (

        <div onBlur={closeInput} key={id}>
            <ToastContainer />


            <>
                <input onChange={(e) => getUsers(e.target.value)} style={{ width: "100%", height: "48px", border: "1px solid #123164", borderRadius: "7px", paddingLeft: "0.9rem", outline: "none" }} />


                {users.length > 0

                    ?
                    <div style={{ width: "240px", border: "1px solid #D7D7D7", background: "white", position: "absolute", zIndex: "10", overflowY: "scroll", height: "200px" }}>



                        {users.map(item => {


                            return (


                                <div key={item.id} onClick={() => addUserInCountage(item.name, item.mail)} style={{ background: "#F7F9FB", borderRadius: "60px", width: "170.73px", height: "39px", marginTop: "9px", marginLeft: "4px", display: "flex", alignItems: "center", cursor: "pointer" }}>

                                    {item.picture != undefined

                                        ?

                                        <img src={item.picture} style={{ marginLeft: "0.5rem", width: "25px", height: "25px", borderRadius: "100%" }} />
                                        :
                                        <BigHead
                                            width={30}
                                            accessory="shades"
                                            body="chest"
                                            circleColor="blue"
                                            clothing="tankTop"
                                            clothingColor="black"
                                            eyebrows="angry"
                                            eyes="wink"
                                            facialHair="mediumBeard"
                                            graphic="vue"
                                            hair="short"
                                            hairColor="black"
                                            hat="none"
                                            hatColor="green"
                                            lashes="false"
                                            lipColor="purple"
                                            mask="true"
                                            faceMask="true"
                                            mouth="open"
                                            skinTone="brown"
                                        />
                                    }


                                    <p className="textNameTableListManager" style={{ marginLeft: "0.5rem" }}>{item.name}</p>

                                </div>

                            )

                        })}

                        <div style={{ height: "20px" }}></div>
                    </div>

                    :
                    <></>
                }


            </>




        </div>
    )
}

export default InputSearchUserCount