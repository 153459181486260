import React, { useEffect, useState } from 'react'
import ThowColumLayout from '../../layouts/ThowColumLayoutInventary'
import Sidebar from '../../components/Sidebar'
import TableListEditStock from '../../components/TableListEditStock'
import HeaderDashboard from '../../components/HeaderDashboard'
import { useTranslation } from 'react-i18next'
const EditStock = () => {
    const {t} = useTranslation()
    const [pageIventory, setPageIventory] = useState()

    useEffect(() => {
        if (window.location.pathname == "/dashboard/edit/stock/inventory") {
            setPageIventory(true)
        }
    }, [])

    const containerSecondary = {

        marginTop: "1.625rem",
        display: "flex",
        flexDirection: "column",
        overFlowY: "scroll",
        overFlowX: "hidden",
        alignItems: "center",

    }


    return (

        <ThowColumLayout
            name="Editar estoque"
            page="addStock"
            background="#F9F9F9"
            height={"auto"}

            colum1={

                <Sidebar page={pageIventory ? "Inventary" : "addStock"} />

            }

            colum2={

                <div style={containerSecondary}>

                    <div style={{ width: "1000px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <div style={{ width: "1020px", display: "flex", flexDirection: "column" }}>
                            <HeaderDashboard page={"/dashboard/inventary"} arrow={true} name={t('Editar Imóvel')} />
                            <TableListEditStock />
                        </div>
                    </div>

                </div>
            }

        />
    )

}

export default EditStock