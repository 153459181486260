import React,{useState,useEffect} from 'react'

import ThowColumLayoutLogin from '../../layouts/ThowColumLayoutLogin'
import landingIcon from '../../images/png/Group 12.png'
import companyIcon from '../../images/png/49fff0ec-610e-4b4a-bcfe-4d03f4bb43c3.4824b178137fe1c2cf94.png'
import Input from '../../components/Input'
import Button from '../../components/Button'

import api from '../../services/api'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Landing = () => {

    const [mail,setMail] = useState()
    const [password,setPassword] = useState()

    /* MESSAGE ERROS */
    
    const [mailError,setMailError] = useState()
    const [paswordError,setPasswordError] = useState()
    
    /* BTN STATE */
    
    const [btnState,setBtnState] = useState()


    const validateEmail = (email) => {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }
    const getLogin = async () => {


            if(password == undefined){

                setPasswordError("senha deve conter no mínimo 8 caracteres")

            }


            const validMail = validateEmail(mail)

            if(validMail == false){
                setMailError("email inválido")
                setBtnState(true)

            }else{
                setMailError()
                setBtnState(false)

            }
        

            if(String(password).length <=7){

                setPasswordError("senha deve conter no mínimo 8 caracteres")
                setBtnState(true)

            }else{

                setBtnState(false)
                setPasswordError()

            }


        if(mail != undefined && password != undefined && validMail != false){

            const response = await api.post("session/storage/panel",{

                "mail":mail,
                "password":password


            })
            if(response.status == 200){
                localStorage.setItem("@USER_NAME",response.data.user.name)
                localStorage.setItem("@USER_MAIL",response.data.user.mail)
                localStorage.setItem("@USER_PERMISSION",response.data.user.permission)
                localStorage.setItem("@USER_PASSWORD",password)
                localStorage.setItem("@USER_TOKEN",response.data.token)

                localStorage.setItem("@USER_PICTURE",response.data.user.picture != null ? response.data.user.picture : false )
                
                window.location.href="/dashboard/inventary"
            }

        
            if(response.status != 200){

                toast.error('Email ou senha inválidos!', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
    

            }


        }
    }

    const image = {
        objectFit:"contain",
        height:"100vh",
    },

    containerColum2 = {

        paddingTop:"7.5rem",

    }

    useEffect(() => {

        if(mail != undefined){

            const validMail = validateEmail(mail)

            if(validMail == false){
                setMailError("email inválido")
                setBtnState(true)

            }else{
                setMailError()
                setBtnState(false)

            }
        }

        if(password != undefined){

            if(String(password).length <=7){

                setPasswordError("senha deve conter no mínimo 8 caracteres")
                setBtnState(true)

            }else{

                setBtnState(false)
                setPasswordError()

            }

        }


    },[mail,password])

    return(

        <ThowColumLayoutLogin 
        page={"Landing"}
        colum1={
            <div>
            <img src={landingIcon} style={image}/>
            </div>
        } 

        colum2={

            <div style={containerColum2}>
            <ToastContainer />
            

            <img src={companyIcon} style={{width:"100px",height:"100px"}}/>

            <h2 className="textPrimaryLanding">Dados de acesso</h2>

            <Input name="E-mail" error={mailError} onChange={(e) => setMail(e.target.value)} placeholder="Insira seu e-mail" type={"text"}/>
            <Input name="Senha" error={paswordError} onChange={(e) => setPassword(e.target.value)} placeholder="Insira sua senha" type={"password"}/>
            
            <Button width="416px" height="48px" background="#123164" 
                    borderRadius="60px" marginTop="3rem" name="Entrar"
                    border="none" disabled={btnState} onClick={() => getLogin()} />

            </div>

        }
        
        
        >

        </ThowColumLayoutLogin>

    )

}

export default Landing