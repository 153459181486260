import React, { useState, useEffect, useContext } from 'react'
import CardTableListManager from '../CardTableListManager'
import api from '../../services/api'
import { CountagesContext } from '../../context/countages'
import { ClientContext } from '../../context/client'
import { BigHead } from '@bigheads/core'
import { ModalContext } from '../../context/modal'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import InputSearchUser from '../InputSearchUser'
import InputUsers from '../InputUsers'
import InputSearchUserCount from '../InputSearchUserCount'
import InputUsersCount from '../InputUsersCount'
import TableCountage from '../TableCountage'
import { useTranslation } from 'react-i18next'

const TableListManager = ({ page, valuePx, setValuePx }) => {
    const {t} = useTranslation()
    const { redirectToPage } = useContext(ModalContext);
    const { data } = useContext(CountagesContext);

    const [countages, setCountages] = useState([]);
    const [valueArray, setValueArray] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const [totalPages, setTotalPages] = useState(1);
    const [stateInput, setStateInput] = useState(false);

    const [stateInputParticipants, setStateInputParticipants] = useState(false);
    const [usersInCountage, setUserInCountage] = useState([]);

    const [idEdit, setIdEdit] = useState();
    const [listParticipants, setListParticipants] = useState([]);
    const [selectType, setSelectType] = useState('')

    /* CLIENT CONTEXT */
    const { dataClient, typeGet, clientType, deleteSelect, setTotalCounts, idsDelete, setIdsDelete } = useContext(ClientContext);

    const get = async (page) => {
        try {

            const response = await api.get(
                `${page === "Inventary" ? "countage" : `countage/all?page=${page}`}`
            );


            if (response.status >= 404) {
                setCountages([]);
                return false;
            }

            const { data, totalPages, totalCount } = response.data;


            setCurrentPage(page);
            setTotalCounts(totalCount)
            setTotalPages(totalPages);

            if (page === 1) {
                setCountages(data);
            } else {
                setCountages((prevCountages) => [...prevCountages, ...data]);
            }

            setValueArray(data.length);
        } catch (error) {
            console.error(error);
        }
    };

    const getClient = async (nextPage) => {
        try {
            const response = await api.get(`countage/by/client/${typeGet[1]}?page=${nextPage}`)


            if (response.status == 404) {
                //setCountages([])
                return false
            } else {

                const { data, totalPages, totalCount } = response.data;

                setTotalCounts(totalCount)
                setCurrentPage(nextPage);
                setTotalPages(totalPages);

                if (nextPage === 1) {
                    setCountages(data);
                } else {
                    setCountages((prevCountages) => [...prevCountages, ...data]);
                }
            }
        } catch (error) {

        }
    }

    const getTypeClient = async (nextPage) => {
        try {
            const response = await api.get(`countage/by/client/${clientType}/${typeGet[1]}?page=${nextPage}`)

            if (response.status == 404) {
                //setCountages([])
                return false
            } else {

                const { data, totalPages, totalCount } = response.data;

                setTotalCounts(totalCount)
                setCurrentPage(nextPage);
                setTotalPages(totalPages);

                if (nextPage === 1) {
                    setCountages(data);
                } else {
                    setCountages((prevCountages) => [...prevCountages, ...data]);
                }
            }
        } catch (error) {

        }
    }

    const getTypeClientLocation = async (nextPage) => {
        try {
            const response = await api.get(`countage/by/client/${clientType}/location/${typeGet[1]}?page=${nextPage}`)

            if (response.status == 404) {
                // setCountages([])
                return false
            } else {

                const { data, totalPages, totalCount } = response.data;

                setTotalCounts(totalCount)
                setCurrentPage(nextPage);
                setTotalPages(totalPages);

                if (nextPage === 1) {
                    setCountages(data);
                } else {
                    setCountages((prevCountages) => [...prevCountages, ...data]);
                }
            }
        } catch (error) {

        }
    }

    const getParticipants = async () => {
        try {
            const listPart = await api.get("/user/countage/participants");
            setListParticipants(listPart);
        } catch (error) {
            console.error(error);
        }
    };

    const addListDelete = async (idCount) => {

        if (deleteSelect) {

            let ids = idsDelete

            const filter = ids.filter(item => item == idCount)

            if (filter.length > 0) {
                const remove = ids.filter(item => item != idCount)
                if (remove.length > 0) {
                    ids = remove
                } else if (remove.length == 0) {
                    ids.push(idCount)
                }
            } else {
                ids.push(idCount)
            }
            setIdsDelete(ids)


            return ids
        }
    }

    const verifyListDelete = (id) => {
        return idsDelete.includes(id)
    }

    useEffect(()=> {
        verifyListDelete(5)
    },[idsDelete])

    useEffect(() => {
        get(1);

        getParticipants();
    }, []);

    useEffect(() => {
        setCurrentPage(1)
        setTotalPages(2)
    }, [typeGet[0]])

    useEffect(() => {
        if (String(data).length > 0) {
            setCountages(data);
        }
    }, [data]);

    useEffect(() => {
        if (dataClient !== undefined) {
            setCountages(dataClient);
        }
    }, [dataClient]);


    useEffect(() => {
        const handleScroll = () => {
            const { scrollTop, scrollHeight, clientHeight } = document.documentElement;

            if (scrollTop + clientHeight >= scrollHeight - 2) {
                // Verifica se a página atual é menor que o total de páginas

                if (currentPage < totalPages) {
                    if (typeGet[0] == 'client') {
                        if (selectType != typeGet[1]) {
                            setSelectType(typeGet[1])
                        }
                        const nextPage = currentPage + 1;

                        getClient(nextPage)
                    }
                    if (typeGet[0] == 'count') {
                        if (selectType != typeGet[1]) {
                            setSelectType(typeGet[1])
                        }
                        const nextPage = currentPage + 1;

                        getTypeClient(nextPage)
                    }
                    if (typeGet[0] == 'location') {
                        if (selectType != typeGet[1]) {
                            setSelectType(typeGet[1])
                        }
                        const nextPage = currentPage + 1;

                        getTypeClientLocation(nextPage)
                    }
                    if (typeGet[0] != 'client' && typeGet[0] != 'count' && typeGet[0] != 'location') {
                        const nextPage = currentPage + 1;
                        get(nextPage);
                    }
                }
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [currentPage, totalPages, typeGet]);

    return (

        <div id="test2" style={{ height: `${valueArray < 5 || valueArray == 0 || String(dataClient).length == 0 ? "100vh" : "100%"}`, overflowX: "hidden", overflowY: "hidden", paddingBottom: "150px" }}>
            <ToastContainer />
            <div style={{

                width: "1020px",
                borderRadius: "10px",
                border: "1px solid #D7D7D7",
                background: "white",
                display: "flex",
                paddingTop: "13px",
                paddingBottom: "13px",
                paddingLeft: "1.5rem",
                paddingRight: "1.5rem",
                marginTop: "0.8125rem",
                position: "absolute",
                zIndex: 20,
                flexDirection: "column",
                gap: "10px"
            }}>
                <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                    <p className='textContageNewUsers'>{t('countParticipants')}</p>
                    <div onClick={() => setStateInputParticipants(!stateInput)} style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "30px",
                        width: "30px",
                        backgroundColor: "#fff",
                        border: "1px solid #d7d7d7",
                        borderRadius: "8px",
                        color: "#123164",
                        fontSize: "20px",
                        cursor: "pointer"
                    }}>+</div>
                </div>

                {

                    stateInputParticipants == true

                        ?


                        <InputSearchUserCount onClick={() => setStateInputParticipants(false)} id={5} idEdit={idEdit}
                            name={""} mail={""} client={""} />
                        :


                        <InputUsersCount listParticipants={listParticipants} />
                }

            </div>

            <div style={{ marginTop: "146px" }}></div>

            {countages?.length > 0 && countages.map((itemCountage, index) => {
                let color = false

                return (
                    <TableCountage
                        isActive={verifyListDelete(itemCountage.id)}
                        deleteSelect={deleteSelect}
                        idEdit={idEdit}
                        idsDelete={idsDelete}
                        itemCountage={itemCountage}
                        page={page}
                        setIdEdit={setIdEdit}
                        setStateInput={setStateInput}
                        stateInput={stateInput}
                        key={itemCountage.id}
                        addListDelete={addListDelete}
                        redirectToPage={redirectToPage}
                    />
                )
            })}

        </div>

    )
}

export default TableListManager