import React,{createContext,useState} from 'react'
import api from '../services/api'
export const UserContext = createContext({})

function UserProvider({children}){

    const [data,setData] = useState([])

    const getByName = async (value) => {
        
        if(value == ""){

            const response = await api.get(`panel/users`)
            setData(response.data)
            return false
        }

        const response = await api.get(`panel/users/by/name/${value}`)
        
        if(response.status == 404){
            return false
        }

        setData(response.data)
    }


    
    return(
    <UserContext.Provider value={{getByName,data}}>
        {children}
    </UserContext.Provider>
    )

}

export default UserProvider