import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ptJson from "./ptBr.json"
import enJson from "./en.json"
import esJson from "./es.json"
import frJson from "./fr.json"
import itJson from "./it.json"
import deJson from "./de.json"

const standardLanguage = localStorage.getItem("@STANDARD_LANGUAGE:")

i18n
    .use(initReactI18next)
    .init({
        fallbackLng: standardLanguage || "pt",
        interpolation: {
            escapeValue: false
        },
        resources: {
            en: enJson,
            pt: ptJson,
            es: esJson,
            fr: frJson,
            it: itJson,
            de: deJson,
        },

    });

export default i18n;