import React, { useState, useEffect } from 'react'
import { BigHead } from '@bigheads/core'
import { Tooltip as ReactTooltip } from "react-tooltip";
import api from '../../services/api'

const InputUsersCount = () => {

    const [usersInCountage, setUserInCountage] = useState([])
    const [users, setUsers] = useState([])
    const arrayPosition = [1, 2, 3, 4]

    const [listParticipants, setListParticipants] = useState([])


    const getParticipants = async () => {
        const listPart = await api.get("/user/countage/participants")
        setListParticipants(listPart.data)
    }

    const removeUser = async (idUser) => {
        console.log(idUser)
        await api.delete(`user/countage/participants/${idUser}`)
        const listPart = await api.get("user/countage/participants")
        setListParticipants(listPart.data)
    }


    useEffect(() => {
        getParticipants()
    }, [])

    return (

        <div style={{ minWidth: "100%", width: usersInCountage.length == 1 || usersInCountage.length == 0 ? "190px" : "auto", height: "48px", border: "1px solid #D7D7D7", borderRadius: "7px", cursor: "pointer", }}>

            <div className='scrollParticipantsv' style={{ display: "flex", alignItems: "center", width: "100%", overflowY: "hidden", overflowX: "auto" }}>

                {listParticipants.length > 0 && listParticipants.map((item, index) => {


                    return (

                        <div data-tooltip-place='top' data-tooltip-position-strategy='fixed' data-tooltip-id="name" data-tooltip-content={`${index + 1}º ${item.name}`} style={{ display: "flex", alignItems: "center", height: "35px", marginTop: "0.3rem", marginLeft: "0.5rem", marginRight: "0.5rem", backgroundColor: "#E4EBFF", gap: "5px", padding: "0 0 0 12px", borderRadius: "60px" }}>
                            <ReactTooltip id='name' />
                            <p className='textCardUserTable' style={{ transform: "translateY(8px)" }}>{`${index + 1}º`}</p>
                            <div style={{ transform: "translateY(-2px)" }}>
                                {item?.picture != undefined

                                    ?

                                    <img src={item.picture} style={{ marginLeft: "0.5rem", width: "65px", height: "65px", borderRadius: "100%" }} />
                                    :
                                    <BigHead
                                        width={30}
                                        accessory="shades"
                                        body="chest"
                                        circleColor="blue"
                                        clothing="tankTop"
                                        clothingColor="black"
                                        eyebrows="angry"
                                        eyes="wink"
                                        facialHair="mediumBeard"
                                        graphic="vue"
                                        hair="short"
                                        hairColor="black"
                                        hat="none"
                                        hatColor="green"
                                        lashes="false"
                                        lipColor="purple"
                                        mask="true"
                                        faceMask="true"
                                        mouth="open"
                                        skinTone="brown"
                                    />
                                }
                            </div>

                            {
                                /* CHECK THE WORD SIZE */
                                String(item.name).split("") < 7 ? (
                                    <>
                                        <p className="textNameTableListManager" style={{ marginLeft: "0.5rem" }}>{item.name}</p>
                                    </>
                                )
                                    :
                                    /* CHECKS FOR NUMBERS TO IDENTIFY, FOR EXAMPLE: NAME 01, NAME 02 */
                                    String(item.name).split(" ")[1] ? (
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <p className="textNameTableListManager" style={{ marginLeft: "0.5rem", whiteSpace: "nowrap", overflow: "hidden", width: "26px", textOverflow: "ellipsis" }}>{item.name}</p>
                                            <p className="textNameTableListManager2" style={{ whiteSpace: "nowrap" }}>{String(item.name).split(" ")[String(item.name).split(" ").length - 1]}</p>
                                        </div>
                                    )
                                        :
                                        /* IF NO NAMES WITH NUMBERS JUST ADD THE THREE POINTS */
                                        (
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <p className="textNameTableListManager" style={{ marginLeft: "0.5rem", whiteSpace: "nowrap", overflow: "hidden", width: "43px", textOverflow: "ellipsis" }}>{item.name}</p>
                                            </div>
                                        )
                            }

                            <p onClick={() => removeUser(item.id)} id="close" style={{ transform: "translateY(10px)", height: "100%", width: "25px" }}>
                                <svg id="close" width="10" height="11" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.54849 4.49999L7.5891 1.45604C7.77784 1.26707 7.77784 0.960688 7.5891 0.77173C7.40036 0.582758 7.09434 0.582758 6.90561 0.77173L3.86532 3.816L0.825045 0.77173C0.636301 0.582758 0.330287 0.582758 0.141558 0.77173C-0.047171 0.960703 -0.0471861 1.26709 0.141558 1.45604L3.18215 4.49999L0.141558 7.54396C-0.0471861 7.73293 -0.0471861 8.03931 0.141558 8.22827C0.330303 8.41723 0.636316 8.41724 0.825045 8.22827L3.86532 5.18399L6.9056 8.22827C7.09434 8.41724 7.40036 8.41724 7.58909 8.22827C7.77783 8.0393 7.77783 7.73292 7.58909 7.54396L4.54849 4.49999Z" fill="black" />
                                </svg>
                            </p>

                        </div>

                    )



                })}

            </div>
        </div>

    )

}

export default InputUsersCount