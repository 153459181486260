import React, { createContext, useState } from 'react'
import api from '../services/api'
export const CountagesContext = createContext({})

function CountagesProvider({ children }) {
    const [countageFile, setCountageFile] = useState({
        filename: "report.csv",
        headers: "",
        data: ""
    })

    const [data, setData] = useState([])

    const getByName = async (value) => {

        if (value != "") {

            const response = await api.get(`countage/by/name/${value}?page=1`)

            if (response.status == 404) {
                setData([])
            }

            setData(response.data.data)
        } else {

            const response = await api.get(`countage/all?page=1`)

            if (response.status == 404) {
                setData([])
            }

            setData(response.data.data)
        }

    }

    const getByNameInventary = async (value) => {

        if (value != "") {

            const response = await api.get(`countage/by/name/inventary/${value}?page=1`)

            if (response.status == 404) {
                setData([])
            }

            setData(response.data.data)
        } else {

            const response = await api.get(`countage/all?page=1`)

            if (response.status == 404) {
                setData([])
            }

            setData(response.data.data)
        }

    }




    return (
        <CountagesContext.Provider value={{ getByName, data, countageFile, setCountageFile, getByNameInventary }}>
            {children}
        </CountagesContext.Provider>
    )

}

export default CountagesProvider