import React, { useState, useContext, useEffect } from 'react'
import CardTableUsers from '../CardTableUsers'
import Modal from 'react-bootstrap/Modal';
import uploadUserIcon from '../../images/svg/uploadUserIcon.svg'
import Input from '../Input'
import InputSelectFilterManager from '../InputSelectFilterManager'
import { ModalContext } from '../../context/modal';
import { UserContext } from '../../context/user';
import camIcon from '../../images/svg/camIcon.svg';

import api from '../../services/api'
import app from '../../firebase/database';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next'


const TableListUser = () => {
    const {t} = useTranslation()

    const [stateCheckPrimary, setStateCheckPrimary] = useState(false)
    const [image, setImage] = useState()
    const [imagePreview, setImagePreview] = useState()
    const [imageFirebase, setImageFirebase] = useState()

    /* MODAL STATES */

    const { modalGlobal, setStateModalGlobal } = useContext(ModalContext)
    const { data } = useContext(UserContext)

    const [modalActions, setModalActions] = useState(false)
    const [modalEdit, setModalEdit] = useState(false)
    const [modalConfirmation, setModalConfirmation] = useState(false)

    const [users, setUsers] = useState([])

    const [name, setName] = useState()
    const [mail, setMail] = useState()
    const [password, setPassword] = useState()

    const [permission, setPermission] = useState()

    /* ERROR MESSAGES */

    const [nameError, setNameError] = useState()
    const [mailError, setMailError] = useState()
    const [passwordError, setPasswordError] = useState()

    const [permissionError, setPermissionError] = useState()

    const [editPermissionSelect,setEditPermissionSelect] = useState()

    /* ID */
    const [id, setId] = useState()
    const [mailRemove, setMailRemove] = useState()

    const styleColum1 = {

        width: "100%",
        border: "1px solid #D7D7D7",
        height: "55px",
        borderRadius: "10px",
        background: "white"

    }
    const btnStyleModal = {

        border: "none",
        outline: "none",
        background: "#123164",
        borderRadius: "50px",
        width: "101px",
        height: "36px",
    }
    const btnStyleRemove = {

        width: "82px",
        height: "37px",
        borderRadius: "50px",
        border: "none",
        background: "#FFE1E1"

    }

    const validateEmail = (email) => {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }
    const loadImage = (file) => {

        setImage(file)
        setImageFirebase(file)
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = async () => {
            setImagePreview(reader.result)

        }

    }
    const closeModal = (value) => {

        setName()
        setMail()
        setPassword()
        setPermission()
        setImagePreview()

        setNameError()
        setMailError()
        setPasswordError()
        setPermissionError()

        setStateModalGlobal(value)

    }
    const closeModalEdit = () => {

        setName()
        setMail()
        setPassword()
        setPermission()
        setImagePreview()

        setNameError()
        setMailError()
        setPasswordError()
        setPermissionError()
        setModalEdit(false)
    }
    const getUsers = async () => {

        const response = await api.get("panel/users")
        if (response.status == 404) {
            setUsers([])
            return false
        }
        setUsers(response.data)


    }
    const storage = async () => {

        if (name == undefined || name == "") {
            setNameError("informe um nome")
        }
        if (mail == undefined) {
            setMailError("informe um email")
        }
        if (password == undefined) {
            setPasswordError("informe uma senha")
        }
        if (permission == undefined) {
            setPermissionError("informe uma permissão")
        }

        const mailIsValid = validateEmail(mail)


        if (name == undefined || mail == undefined || password == undefined || permission == undefined || mailIsValid == false || String(password).length <= 7) {

            return false

        }

        if (name == "" || mail == "" || password == "" || permission == "") {
            return false
        }

        if (image == undefined) {


            const response = await api.post("panel/storage", {

                "name": name,
                "mail": mail,
                "permission": permission,
                "password_hash": password

            })

            if (response.status == 200) {

                toast.success('Cliente adicionado com sucesso!', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });

                setTimeout(() => {

                    window.location.href = "/dashboard/users"


                }, [3000])

            } else {


                // toast.error('Opss.. cliente já existente!', {
                //     position: "top-right",
                //     autoClose: 2000,
                //     hideProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     draggable: true,
                //     progress: undefined,
                //     theme: "light",
                // });

            }

            return false

        }


        toast.success('Adicionando cliente....', {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });




        app.storage().ref(`/users/${imageFirebase.name}`).put(imageFirebase).then(() => {

            app.storage().ref(`/users/${image.name}`).getDownloadURL().then(async (image) => {


                const response = await api.post("panel/storage", {

                    "name": name,
                    "mail": mail,
                    "permission": permission,
                    "password_hash": password,
                    "image": image


                })

                if (response.status == 200) {

                    toast.success('Cliente adicionado com sucesso!', {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });

                    setTimeout(() => {

                        window.location.href = "/dashboard/users"


                    }, [3000])

                } else {

                    toast.error('Opss.. cliente já existente!', {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }

            })

        }).catch(error => {

            window.alert(error)

        })


    }
    const update = async () => {

        if (image != undefined) {


            toast.success('alterando cliente....', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });


            app.storage().ref(`/users/${image.name}`).put(image).then(() => {

                app.storage().ref(`/users/${image.name}`).getDownloadURL().then(async (url) => {

                    const response = await api.put("panel/user/", {

                        "id": id,
                        "name": name,
                        "mail": mail,
                        "permission": permission,
                        "password_hash": password,
                        "picture": url


                    })


                    toast.success('Cliente alterado com sucesso!', {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });

                    setTimeout(() => {

                        window.location.href = "/dashboard/users"


                    }, [3000])

                })

            })

            return false

        }


        const response = await api.put("panel/user", {

            "id": id,
            "name": name,
            "mail": mail,
            "permission": permission,
            "password_hash": password,


        })

        if (response.status == 200) {


            toast.success('Cliente alterado com sucesso!', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

            setTimeout(() => {

                window.location.href = "/dashboard/users"


            }, [3000])

        }

    }
    const getModalActions = async (id, mail) => {
        setId(id)
        setMailRemove(mail)

        setModalActions(true)
        const response = await api.get(`user/by/id/${id}`)
        setName(response.data[0].name)

    }
    const getModalConfirmation = () => {

        setModalActions(false)
        setModalConfirmation(true)

    }
    const remove = async () => {

        try {
            const response = await api.delete(`panel/user/delete/${id}`)   
            if(response.status == 200 || response.status == 404){                
            toast.success("Usuário deletado com sucesso!", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

            setTimeout(() => {
                window.location.href = "/dashboard/users"
            }, 2000);
        }
        } catch (error) {

            setTimeout(() => {
                window.location.href = "/dashboard/users"
            }, 2000);
        }


    }
    const edit = async () => {
        setModalActions(false)
        setModalEdit(true)
        const response = await api.get(`panel/users/by/id/${id}`)
        setName(response.data[0].name)
        setMail(response.data[0].mail)
        setPermission(response.data[0].permission)
        setImagePreview(response.data[0].image)
    }

    useEffect(() => {

        if (name != undefined) {
            setNameError()
        }
        if (mail != undefined) {
            setMailError()
        }
        if (password != undefined) {
            setPasswordError()
        }
        if (permission != undefined) {
            setPermissionError()
        }

        if (mail != undefined) {

            const mailIsValid = validateEmail(mail)

            if (mailIsValid == false) {

                setMailError("email inválido")
            }


        }


        if (String(password).length <= 7) {

            setPasswordError("senha deve conter no minimo 8 caracteres")

        }


    }, [name, mail, password, permission])

    useEffect(() => {

        getUsers()

    }, [])

    useEffect(() => {

        if (String(data).length > 0) {
            setUsers(data)

        }

    }, [data])


    return (

        <div style={{ height: "100vh" }}>
            <ToastContainer />

            <div style={styleColum1}>


                <div style={{ position: "relative", top: "14px", left: "17px" }}>
                    <input type="checkbox" style={{ cursor: "pointer" }} onClick={() => setStateCheckPrimary(!stateCheckPrimary)} />
                </div>


                <div style={{ display: "flex", justifyContent: "space-around" }}>

                    <div >
                        <p className="textNameTableListUser">{t('nameUser')}</p>
                    </div>

                    <div >
                        <p className="textNameTableListUser">{t('mail')}</p>
                    </div>

                    <div>
                        <p className="textNameTableListUser">{t('permission')}</p>
                    </div>


                </div>
            </div>

            {users.map(item => {

                return (

                    <CardTableUsers onClick={() => getModalActions(item.id, item.mail)} picture={item.image} name={item.name} mail={item.mail} level={item.permission} length={users.length} checkNotVisible={stateCheckPrimary} />


                )

            })}


            <Modal
                dialogClassName="modal-filter-table-list-user"
                show={modalGlobal}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header >

                    <div style={{ display: "flex", height: "30px" }}>
                        <div onClick={() => closeModal(false)} style={{ cursor: "pointer" }}>
                            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_0_2887)">
                                    <path d="M7.49864 6.3679L12.5016 1.35941C12.8122 1.04847 12.8122 0.544354 12.5016 0.233445C12.1911 -0.0774893 11.6876 -0.0774893 11.377 0.233445L6.37456 5.24246L1.37211 0.233445C1.06155 -0.0774893 0.55804 -0.0774893 0.247506 0.233445C-0.0630274 0.544378 -0.0630522 1.0485 0.247506 1.35941L5.25048 6.3679L0.247506 11.3764C-0.0630522 11.6874 -0.0630522 12.1915 0.247506 12.5024C0.558065 12.8133 1.06158 12.8133 1.37211 12.5024L6.37456 7.49334L11.377 12.5024C11.6876 12.8133 12.1911 12.8133 12.5016 12.5024C12.8122 12.1915 12.8122 11.6873 12.5016 11.3764L7.49864 6.3679Z" fill="black" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_0_2887">
                                        <rect width="12.72" height="12.7354" fill="white" transform="translate(0.0143433)" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>

                        <p className="textPrimaryModalFilterManager">{t('newUser')}</p>
                    </div>

                    <button className="textBtnFilterManagerModal" onClick={() => storage()} style={btnStyleModal} >
                        Criar
                    </button>


                </Modal.Header>
                <Modal.Body>

                    <input onChange={(e) => loadImage(e.target.files[0])} accept='image/png' name="file" id="file" type="file" style={{ display: "none" }} />

                    {imagePreview != undefined

                        ?

                        <label htmlFor='file' style={{ display: "flex", justifyContent: "center", marginBottom: "3.93rem", marginTop: "2.12rem" }}>
                            <img src={"data:image/;base64;" + imagePreview} style={{ width: "120px", height: "120px", borderRadius: "100%",objectFit:"contain" }} />
                        </label>

                        :

                        <label htmlFor='file' style={{ display: "flex", justifyContent: "center", marginBottom: "3.93rem" }}>
                            <img src={uploadUserIcon} />
                        </label>

                    }


                    <div style={{ display: "flex", justifyContent: "space-around" }}>
                        <div style={{ position: "relative" }}>
                            <Input onChange={(e) => setName(e.target.value)} color="black" width="265px" height="45px" name={t('nameUser')} />
                            <p className="errorMessage">{nameError != undefined ? nameError : <></>}</p>

                        </div>

                        <div style={{ position: "relative" }}>
                            <Input onChange={(e) => setMail(e.target.value)} color="black" width="265px" height="45px" name="E-mail" />
                            <p className="errorMessage">{mailError != undefined ? mailError : <></>}</p>

                        </div>

                    </div>

                    <div style={{ display: "flex", justifyContent: "space-around" }}>
                        <div style={{ position: "relative" }}>
                            <Input onChange={(e) => setPassword(e.target.value)} type="password" topEye="46px" rightEye="10px" color="black" width="265px" height="45px" name={t('password')} />
                            <p className="errorMessage">{passwordError != undefined ? passwordError : <></>}</p>

                        </div>

                        <div style={{ position: "relative" }}>
                            <InputSelectFilterManager onChange={(e) => setPermission(e.target.value)} type="addUser" marginRight="0" top="44px" width="265px" height="45px" name={t('permission')} custom={true} />
                            <p className="errorMessage">{permissionError != undefined ? permissionError : <></>}</p>

                        </div>

                    </div>


                </Modal.Body>



            </Modal>

            <Modal
                dialogClassName="modal-filter-table-list-user-edit"
                show={modalActions}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header >

                    <div style={{ display: "flex", height: "30px", justifyContent: "space-between", width: "100%" }}>

                        <p className="textPrimaryModalFilterManager" style={{ position: "relative", right: "17px" }}>{t('actions')}</p>

                        <div onClick={() => setModalActions(false)} style={{ cursor: "pointer" }}>
                            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_0_2887)">
                                    <path d="M7.49864 6.3679L12.5016 1.35941C12.8122 1.04847 12.8122 0.544354 12.5016 0.233445C12.1911 -0.0774893 11.6876 -0.0774893 11.377 0.233445L6.37456 5.24246L1.37211 0.233445C1.06155 -0.0774893 0.55804 -0.0774893 0.247506 0.233445C-0.0630274 0.544378 -0.0630522 1.0485 0.247506 1.35941L5.25048 6.3679L0.247506 11.3764C-0.0630522 11.6874 -0.0630522 12.1915 0.247506 12.5024C0.558065 12.8133 1.06158 12.8133 1.37211 12.5024L6.37456 7.49334L11.377 12.5024C11.6876 12.8133 12.1911 12.8133 12.5016 12.5024C12.8122 12.1915 12.8122 11.6873 12.5016 11.3764L7.49864 6.3679Z" fill="black" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_0_2887">
                                        <rect width="12.72" height="12.7354" fill="white" transform="translate(0.0143433)" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>

                    </div>


                </Modal.Header>
                <Modal.Body style={{ padding: "0px" }}>

                    <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>

                        <div onClick={() => edit()} style={{ height: "55px", width: "100%", borderBottom: "1px solid #D7D7D7", textAlign: "center", paddingTop: "0.9rem", cursor: "pointer" }}>
                            <p className="textModalTableListUserEditPrimary">{t('editAccess')}</p>
                        </div>



                        {users.length == 1

                            ?
                            <></>
                            :
                            <div onClick={() => getModalConfirmation()} style={{ height: "55px", width: "100%", borderBottom: "1px solid #D7D7D7", textAlign: "center", paddingTop: "0.9rem", cursor: "pointer" }}>
                                <p className='textModalTableListUserEditSecondary'>{t('deleteAccess')}</p>
                            </div>
                        }



                        <div style={{ height: "70px" }}></div>

                    </div>

                </Modal.Body>



            </Modal>

            <Modal
                dialogClassName="modal-filter-table-list-user"
                show={modalEdit}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header >

                    <div style={{ display: "flex", height: "30px" }}>
                        <div onClick={() => closeModalEdit(false)} style={{ cursor: "pointer" }}>
                            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_0_2887)">
                                    <path d="M7.49864 6.3679L12.5016 1.35941C12.8122 1.04847 12.8122 0.544354 12.5016 0.233445C12.1911 -0.0774893 11.6876 -0.0774893 11.377 0.233445L6.37456 5.24246L1.37211 0.233445C1.06155 -0.0774893 0.55804 -0.0774893 0.247506 0.233445C-0.0630274 0.544378 -0.0630522 1.0485 0.247506 1.35941L5.25048 6.3679L0.247506 11.3764C-0.0630522 11.6874 -0.0630522 12.1915 0.247506 12.5024C0.558065 12.8133 1.06158 12.8133 1.37211 12.5024L6.37456 7.49334L11.377 12.5024C11.6876 12.8133 12.1911 12.8133 12.5016 12.5024C12.8122 12.1915 12.8122 11.6873 12.5016 11.3764L7.49864 6.3679Z" fill="black" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_0_2887">
                                        <rect width="12.72" height="12.7354" fill="white" transform="translate(0.0143433)" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>

                        <p className="textPrimaryModalFilterManager">{t('editAccess')}</p>
                    </div>

                    <button className="textBtnFilterManagerModal" onClick={() => update()} style={btnStyleModal} >
                        Editar
                    </button>


                </Modal.Header>
                <Modal.Body>

                    <input onChange={(e) => loadImage(e.target.files[0])} accept='image/png' name="file" id="file" type="file" style={{ display: "none" }} />

                    {imagePreview != undefined

                        ?

                        <label htmlFor='file' style={{ display: "flex", justifyContent: "center", marginBottom: "3.93rem", marginTop: "2.12rem" }}>
                            <div style={{position: "relative"}}>
                                <img src={imagePreview} style={{ width: "100px", height: "100px", borderRadius: "100%", objectFit: "contain" }} />
                                <img src={camIcon} style={{ position: "absolute", bottom: "5px", right: "0px", cursor: "pointer" }} />
                            </div>
                        </label>

                        :

                        <label htmlFor='file' style={{ display: "flex", justifyContent: "center", marginBottom: "3.93rem" }}>
                            <img src={uploadUserIcon} style={{ width: "100px", height: "100px", borderRadius: "100%" }} />
                        </label>

                    }


                    <div style={{ display: "flex", justifyContent: "space-around" }}>
                        <div style={{ position: "relative" }}>
                            <Input value={name} onChange={(e) => setName(e.target.value)} color="black" width="265px" height="45px" name="Nome do usuário" />
                            <p className="errorMessage">{nameError != undefined ? nameError : <></>}</p>

                        </div>

                        <div style={{ position: "relative" }}>
                            <Input value={mail} onChange={(e) => setMail(e.target.value)} color="black" width="265px" height="45px" name="E-mail" />
                            <p className="errorMessage">{mailError != undefined ? mailError : <></>}</p>

                        </div>

                    </div>

                    <div style={{ display: "flex", justifyContent: "space-around" }}>
                        <div style={{ position: "relative" }}>
                            <Input onChange={(e) => setPassword(e.target.value)} type="password" topEye="46px" rightEye="10px" color="black" width="265px" height="45px" name="Senha" />
                            <p className="errorMessage">{passwordError != undefined ? passwordError : <></>}</p>

                        </div>
                        {editPermissionSelect == false 
                        
                        ?
                        <div style={{ position: "relative" }}>
                            <InputSelectFilterManager onBlur={() => setEditPermissionSelect(false)} onClick={() => setEditPermissionSelect(true)} value={permission} onChange={(e) => setPermission(e.target.value)} type="addUser" marginRight="0" top="44px" width="265px" height="45px" name="Permissão" custom={true} />
                            <p className="errorMessage">{permissionError != undefined ? permissionError : <></>}</p>

                        </div>    
                        :

                        <div style={{ position: "relative" }}>
                        <InputSelectFilterManager onBlur={() => setEditPermissionSelect(false)} onClick={() => setEditPermissionSelect(true)}  onChange={(e) => setPermission(e.target.value)} type="addUser" marginRight="0" top="44px" width="265px" height="45px" name="Permissão" custom={true} />
                        <p className="errorMessage">{permissionError != undefined ? permissionError : <></>}</p>

                    </div>

                        }
                    

                    </div>


                </Modal.Body>



            </Modal>

            <Modal
                dialogClassName="modal-filter-table-list-user-edit"
                show={modalConfirmation}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header >

                    <div style={{ display: "flex", height: "30px", justifyContent: "space-between", width: "100%" }}>

                        <div style={{ display: "flex", alignItems: "center" }}>


                            <div onClick={() => setModalConfirmation(false)} style={{ cursor: "pointer" }}>
                                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_0_2887)">
                                        <path d="M7.49864 6.3679L12.5016 1.35941C12.8122 1.04847 12.8122 0.544354 12.5016 0.233445C12.1911 -0.0774893 11.6876 -0.0774893 11.377 0.233445L6.37456 5.24246L1.37211 0.233445C1.06155 -0.0774893 0.55804 -0.0774893 0.247506 0.233445C-0.0630274 0.544378 -0.0630522 1.0485 0.247506 1.35941L5.25048 6.3679L0.247506 11.3764C-0.0630522 11.6874 -0.0630522 12.1915 0.247506 12.5024C0.558065 12.8133 1.06158 12.8133 1.37211 12.5024L6.37456 7.49334L11.377 12.5024C11.6876 12.8133 12.1911 12.8133 12.5016 12.5024C12.8122 12.1915 12.8122 11.6873 12.5016 11.3764L7.49864 6.3679Z" fill="black" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_0_2887">
                                            <rect width="12.72" height="12.7354" fill="white" transform="translate(0.0143433)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>

                            <p className="textPrimaryModalFilterManager" style={{ position: "relative", left: "4px", top: "10px" }}>{t('deleteUser')}</p>

                        </div>

                        <button className="textBtnRemove" onClick={() => remove()} style={btnStyleRemove}>{t('delete')}</button>

                    </div>


                </Modal.Header>
                <Modal.Body style={{ padding: "17px" }}>

                    <p className="textConfirmationRemove">{t('confirmDelet')} <b>{name}</b> ?</p>
                    <div style={{ height: "40px" }}></div>
                </Modal.Body>



            </Modal>


        </div>



    )
}

export default TableListUser