import React, { useState, useEffect } from 'react'
import InputSelectFilterManager from '../InputSelectFilterManager'
import Input from '../Input'
import loadingGif from '../../images/png/wired-flat-63-home.gif'

import InputMaskMoney from '../InputMaskMoney';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../services/api';
import app from '../../firebase/database';
import { useTranslation } from 'react-i18next';
import deleteImageIcon from '../../images/svg/deleteImageIcon.svg'

import InputCheckbox from '../InputCheckbox';

const TableListAddStock = () => {

    const { t } = useTranslation()

    /* INFORMATION IMMOBILE */
    const [goal, setGoal] = useState()
    const [typeImmobile, setTypeImmobile] = useState()
    const [location, setLocation] = useState()
    const [imagesBase64, setImagesBase64] = useState([])

    const [bathRoom, setBathroomn] = useState()
    const [description, setDescription] = useState()
    const [beadNum, setBeadNum] = useState()
    const [district, setDistrict] = useState()
    const [suite, setSuite] = useState()
    const [iptu, setIptu] = useState("0")

    const [value, setValue] = useState("0")
    const [squareMeters, setSquareMeters] = useState()
    const [groundMeters, setGroundMeters] = useState()
    const [vacancy, setVacany] = useState()
    const [zipCode, setZipCode] = useState()
    const [longradouro, setLongradouro] = useState()
    const [state, setState] = useState()
    const [neighborhood, setNeighboarhood] = useState()
    const [city, setCity] = useState()
    const [street, setStreet] = useState()
    const [number, setNumber] = useState()
    const [code, setCode] = useState()
    const [animation, setAnimation] = useState(false)

    const [emphasis, setEmphasis] = useState()
    const [pet, setPet] = useState()
    const [imagesFirebase, setImagesFirebase] = useState([])
    const [characters, setCharacters] = useState([])

    /* ERROR MESSAGES */
    const [goalError, setGoalError] = useState()
    const [typeImmobileError, setTypeMobileError] = useState()
    const [districtError, setDistrictError] = useState()
    const [descriptionError, setDescriptionError] = useState()
    const [bathRoomError, setBathRoomError] = useState()
    const [productError, setProductError] = useState()
    const [optionsCheck, setOptionsCheck] = useState([])
    const [suiteError, setSuiteError] = useState()
    const [beadNumError, setBeadNumError] = useState()
    const [vacancyError, setVacancyError] = useState()
    const [squareMetersError, setSquareMetersError] = useState()
    const [groundMetersError, setGroundMetersError] = useState()
    const [valueError, setValueError] = useState()
    const [iptuError, setIptuError] = useState()
    const [imagesBase64Error, setImagesBase64Error] = useState()
    const [emphasisError, setEmphasisError] = useState()
    const [cepError, setCepError] = useState()
    const [stateError, setStateError] = useState()
    const [cityError, setCityError] = useState()
    const [streetError, setStreetError] = useState()
    const [numberError, setNumberError] = useState()
    const [codeError, setCodeError] = useState()
    const [neighborhoodError,setNeighboarhoodError] = useState()


    const removeImage = (image, imageData) => {

        const array = imagesBase64.slice()
        let index = 0

        array.map((item, i) => {
            if (item?.image && item.image === image) {
                index = i
            } else if (item === imageData) {
                index = i
            }
        })
        array.splice(index, 1)
        imagesFirebase.splice(index, 1)
        setImagesBase64(array)

    }
    const storage = async () => {

        if (goal == undefined) {
            setGoalError("Informe uma finalidade!")
        }
        if (typeImmobile == undefined) {
            setTypeMobileError("Informe um tipo!")
        }

        if (description == undefined) {
            setDescriptionError("Informe uma descrição")
        }

        if (bathRoom == undefined) {
            setBathRoomError("informe um número")
        }
        if (suite == undefined) {
            setSuiteError("informe um número")
        }
        if (beadNum == undefined) {
            setBeadNumError("Informe um número")
        }
        if (vacancy == undefined) {
            setVacancyError("Informe um número")
        }
        if (squareMeters == undefined) {
            setSquareMetersError("Informe um número")
        }
        if (groundMeters == undefined) {
            setGroundMetersError("Informe um número")
        }
        if (value == "0") {
            setValueError("Informe um número")
        }
        if (code == undefined) {
            setCodeError("Informe um número")
        }
        if (iptu == "0") {
            setIptuError("Informe um número")
        }
        if (imagesBase64.length == 0) {
            setImagesBase64Error("Informe uma imagem")
        }

        if (emphasis == undefined) {
            setEmphasisError("Informe um valor")
        }

        if (zipCode == undefined) {
            setCepError("Informe um valor")
        }

        if (state == undefined) {
            setStateError("Informe um valor")
        }

        if (city == undefined) {
            setCityError("Informe um valor")
        }

        if (street == undefined) {
            setStreetError("Informe um valor")
        }

        if (number == undefined) {
            setNumberError("Informe um valor")
        }
        if(neighborhood == undefined){
            setNeighboarhoodError("Informe um valor")
        }

        if (goal == undefined || typeImmobile == undefined || description == undefined || bathRoom == undefined || suite == undefined || beadNum == undefined || vacancy == undefined || squareMeters == undefined || groundMeters == undefined || value == "0" || code == undefined || iptu == "0" || imagesBase64.length == 0 || emphasis == undefined || zipCode == undefined || state == undefined || city == undefined || street == undefined || neighborhood == undefined
        ) {
            return false
        }
        const formatedValue = value.replace(".", "")
        const formatedValueSecondary = formatedValue.replace(",", "")

        const finalValue = (formatedValueSecondary / 100)
        const response = await api.post("panel/storage/immobile", {
            "name": localStorage.getItem("@USER_NAME"),
            "goal": goal,
            "description": description,
            "location": location,
            "type": typeImmobile,
            "bead_num": beadNum,
            "bathroom_num": bathRoom,
            "square_meters": squareMeters,
            "ground_meters": groundMeters,
            "value": finalValue,
            "iptu": iptu,
            "suite_num": suite,
            "vacancies_num": vacancy,
            "emphasis": emphasis,
            "code": code,
            "pet": pet

        })


        if (response.status == 200) {


            try {

                setAnimation(true)

                await Promise.all(

                    imagesFirebase.map(async item => {
                        await app.storage().ref(`/immobils/images/${item.image.name}`).put(item.image).then(() => {

                            app.storage().ref(`/immobils/images/${item.image.name}`).getDownloadURL().then(async (url) => {

                                const responseImages = await api.post("panel/storage/immobile/image", {
                                    "id_prop": response.data.id_prop,
                                    "image": url
                                })

                            })
                        }).catch((err) => {
                            console.log(err)
                        })
                    })


                )

            } catch (error) {

            }

            optionsCheck.map(async item => {

                await api.post("panel/storage/immobile/character", {
                    "name": item,
                    "id_prop": response.data.id_prop
                })
            })

            const responseAddresses = await api.post(`panel/storage/immobile/address`, {
                "id_prop": response.data.id_prop,
                "cep": zipCode,
                "state": state,
                "city": city,
                "street": street,
                "neighborhood": neighborhood,
                "number": number,
            })



            toast.success('Imóvel adicionado com sucesso!', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

            setTimeout(() => {

                setAnimation(false)

                window.location.href = "/dashboard/add/stock"

            }, [3000])

        }

    }
    const getAllCharacters = async () => {

        const response = await api.get("panel/characters")

        if (response.status == 404) {
            return false
        }
        setCharacters(response.data)

    }

    useEffect(() => {

        getAllCharacters()

    }, [])

    const storageCharacter = (e) => {

        const duplicate = optionsCheck.findIndex(item => item === e)
        if (duplicate == -1) {
            setOptionsCheck([e, ...optionsCheck])
        } else {
            const newOptionsCheck = optionsCheck.filter((item, index) => index !== duplicate);
            setOptionsCheck(newOptionsCheck);
        }

    }

    const getImages = (file) => {

        if (imagesBase64.length == 10) {

            toast.error('Limite de imagens atingido!', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });


            return false

        }

        const reader = new FileReader()

        reader.readAsDataURL(file)

        reader.onload = () => {
            setImagesBase64([...imagesBase64, reader.result])
        }

        const images = [...imagesFirebase]
        images.push({ image: file })
        setImagesFirebase(images)

    }

    const getCep = async (cep) => {
        setZipCode(cep)
        const respose = await fetch(`https://viacep.com.br/ws/${cep}/json/`).then(result => result.json())
        setState(respose.uf)
        setCity(respose.localidade)
        setStreet(respose.logradouro)
        setLongradouro(respose.logradouro)
    }


    const styleColum1 = {

        width: "100%",
        border: "1px solid #D7D7D7",
        borderRadius: "10px",
        paddingLeft: "21px",
        height: "190px",
        paddingTop: "25px",
        background: "white",
    }

    const styleColum2 = {

        marginTop: "2.93rem",
        width: "100%",
        border: "1px solid #D7D7D7",
        borderRadius: "10px",
        paddingLeft: "21px",
        paddingTop: "25px",
        background: "white",

    }

    const styleColum3 = {

        marginTop: "2.93rem",
        width: "100%",
        border: "1px solid #D7D7D7",
        borderRadius: "10px",
        height: "250px",
        paddingLeft: "21px",
        paddingTop: "25px",
        background: "white",

    }

    const styleRowInputs = {
        display: "flex",
        width: "100%",

    }

    const btnStyle = {

        width: "165px",
        height: "45px",
        borderRadius: "60px",
        background: "#123164",
        border: "none",
        marginTop: "2.18rem",
        marginBottom: "2.18rem",
        outline: "none"

    }

    useEffect(() => {

        if (goal != undefined) {
            setGoalError("")
        }

        if (typeImmobile != undefined) {
            setTypeMobileError()
            setTypeMobileError("")
        }

        if (emphasis != undefined) {
            setEmphasisError("")
        }

        if (bathRoom != undefined) {
            setBathRoomError("")
        }


        if (beadNum != undefined) {
            setBeadNumError("")
        }

        if (suite != undefined) {
            setSuiteError("")
        }

        if (vacancy != undefined) {
            setVacancyError("")
        }

        if (squareMeters != undefined) {
            setSquareMetersError("")
        }

        if (groundMeters != undefined) {
            setGroundMetersError("")
        }

        if (value != "0") {
            setValueError("")
        }

        if (iptu != "0") {
            setIptuError("")
        }

        if (imagesBase64.length > 0) {
            setImagesBase64Error("")
        }


    }, [goal, typeImmobile, emphasis, bathRoom, suite, vacancy, beadNum, squareMeters, imagesBase64, iptu, value, squareMeters])

    useEffect(() => {

        if (goal == "Selecione") {
            setGoalError("Informe uma finalidade")
        }

        if (typeImmobile == "Selecione") {
            setTypeMobileError("Informe um tipo")
        }

        if (emphasis == "Selecione") {
            setEmphasisError("Informe um valor")
        }

        if (description != undefined) {
            setDescriptionError("")
        }

    }, [goal, typeImmobile, emphasis, description])


    return (

        <div>

            {animation == true

                ?
                <div className='containerLoadingGif'>

                    <img src={loadingGif} />

                </div>

                :
                <></>
            }

            <ToastContainer />

            <div style={styleColum1}>

                <p className="textPrimaryTableListAddStock">{t('informações iniciais')}</p>

                <div style={styleRowInputs}>
                    <div style={{ position: "relative" }}>
                        <InputSelectFilterManager page="add" type={"goal"} onChange={(e) => setGoal(e.target.value)} color="black" custom={true} top={"43px"} width="295px" name={t('Finalidade')} />
                        <p className="errorMessage">{goalError != undefined ? goalError : <></>}</p>
                    </div>

                    <div style={{ position: "relative" }}>
                        <InputSelectFilterManager error={typeImmobileError} onChange={(e) => setTypeImmobile(e.target.value)} type="typeImmobile" color="black" custom={true} top={"43px"} width="295px" name={t('Tipo imóvel')} />
                        <p className="errorMessage">{typeImmobileError != undefined ? typeImmobileError : <></>}</p>
                    </div>

                    <div style={{ position: "relative" }}>
                        <InputSelectFilterManager type={"emphasis"} onChange={(e) => setEmphasis(e.target.value)} color="black" custom={true} top={"43px"} width="295px" name={t('Destaque')} />
                        <p className="errorMessage">{emphasisError != undefined ? emphasisError : <></>}</p>
                    </div>

                </div>

            </div>


            <div style={styleColum2}>
                <p className="textPrimaryTableListAddStock">{t('informações endereço')}</p>

                <div style={styleRowInputs}>
                    <div style={{ position: "relative" }}>
                        <Input mask={"99999-999"} error={cepError} onBlur={(e) => getCep(e.target.value)} color="black" type="text" name={t('Cep')} width="167.4px" />
                    </div>
                    <div style={{ position: "relative" }}>

                        <Input value={state} error={stateError} onChange={(e) => setState(e.target.value)} color="black" type="text" name={t('Estado')} marginLeft={"20px"} width="167.4px" />
                    </div>
                    <div style={{ position: "relative" }}>
                        <Input value={city} error={cityError} onChange={(e) => setCity(e.target.value)} color="black" type="text" name={t('Cidade')} marginLeft={"20px"} width="167.4px" />
                    </div>
                    <div style={{ position: "relative" }}>
                        <Input value={longradouro} error={streetError} onChange={(e) => setStreet(e.target.value)} color="black" type="text" name={t('Rua')} marginLeft={"20px"} width="167.4px" />
                    </div>


                    <div style={{ position: "relative" }}>
                        <Input onChange={(e) => setNumber(e.target.value)} color="black" type="text" name={t('Número')} marginLeft={"20px"} width="167.4px" />
                    </div>
                </div>

                <div style={{ position: "relative" }}>

                    <Input error={neighborhoodError} onChange={(e) => setNeighboarhood(e.target.value)} color="black" type="text" name={t('Bairro')} marginLeft={"0px"} width="167.4px" />
                </div>


            </div>


            <div style={styleColum2}>

                <p className="textPrimaryTableListAddStock">{t('informações imóvel')}</p>

                <div style={styleRowInputs}>
                    <div style={{ position: "relative" }}>
                        <Input onChange={(e) => setDescription(e.target.value)} color="black" type="text" name={t('description')} width="620px" />
                        <p className="errorMessage">{descriptionError != undefined ? descriptionError : <></>}</p>
                    </div>
                    <div style={{ position: "relative" }}>
                        <Input mask={"99999"} error={bathRoomError} onChange={(e) => setBathroomn(e.target.value)} color="black" type="text" marginLeft="20px" name={t('Qtd banheiros')} width="295px" />
                        <p className="errorMessage" style={{ marginLeft: "20px" }}>{productError != undefined ? productError : <></>}</p>
                    </div>
                </div>

                <div style={styleRowInputs}>

                    <div style={{ position: "relative" }}>
                        <Input mask={"99999"} error={beadNumError} onChange={(e) => setBeadNum(e.target.value)} color="black" type="text" marginLeft="0px" name={t('Qtd dormitórios')} width="167.4px" />
                    </div>


                    <div style={{ position: "relative" }}>
                        <Input mask={"99999"} error={suiteError} onChange={(e) => setSuite(e.target.value)} color="black" type="text" marginLeft="20px" name={t('Qtd suites')} width="167.4px" />
                    </div>


                    <div style={{ position: "relative" }}>
                        <Input mask={"99999"} error={vacancyError} onChange={(e) => setVacany(e.target.value)} color="black" type="text" marginLeft="20px" name={t('Qtd vagas')} width="167.4px" />
                    </div>



                    <div style={{ position: "relative" }}>
                        <Input error={squareMetersError} onChange={(e) => setSquareMeters(e.target.value)} color="black" type="text" marginLeft="20px" name={t('Área construída m²')} width="167.4px" />
                    </div>

                    <div style={{ position: "relative" }}>
                        <Input error={groundMetersError} onChange={(e) => setGroundMeters(e.target.value)} color="black" type="text" marginLeft="20px" name={t('Área do terreno m²')} width="167.4px" />
                    </div>



                </div>

                <div style={styleRowInputs}>

                    <div style={{ position: "relative" }}>
                        <InputMaskMoney fixed={2} height={"45px"} type="money" error={valueError} value={value} onChange={(e) => setValue(e.target.value)} color="black" name={t('Preço')} width="167.4px" />
                    </div>

                    <div style={{ position: "relative" }}>
                        <Input mask={"999999999"} error={groundMetersError} onChange={(e) => setCode(e.target.value)} color="black" type="text" marginLeft="20px" name={t('Código Imóvel')} width="167.4px" />
                    </div>

                    <div style={{ position: "relative" }}>
                        <InputMaskMoney fixed={2} height={"45px"} type="money" error={iptuError} value={iptu} onChange={(e) => setIptu(e.target.value)} color="black" marginLeft="20px" name={t('Iptu')} width="167.4px" />
                    </div>

                    <div style={{ position: "relative" }}>
                        <Input onChange={(e) => getImages(e.target.files[0])} error={imagesBase64Error} style={{ appearance: "none" }} height={"45px"} color="black" type="file" marginLeft="20px" name={t('Imagem')} width="167.4px" />

                    </div>
                </div>


                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {imagesBase64.map(item => {


                        return (
                            <div className='image_preview_base64' style={{ position: "relative", marginRight: "3rem", marginLeft: "0.7rem", marginBottom: "3rem" }}>
                                <img src={item?.image == undefined ? item : "data:image/;base64;" + item.image} style={{ width: "200px", height: "150px", borderRadius: "3px", marginTop: "32px" }} />

                                <img src={deleteImageIcon} style={{ position: "absolute", top: "20px", right: "-10px", background: "white", zIndex: "1px", borderRadius: "30px", cursor: "pointer" }} onClick={() => removeImage(item.image, item)} />

                            </div>
                        )

                    })}

                </div>


            </div>

            <div style={styleColum3}>

                <p className="textPrimaryTableListAddStock">{t('Características imóvel')}</p>



                <div style={{ display: "flex", flexWrap: "wrap", width: "90%" }}>
                    {characters.map(item => {
                        return (

                            <div style={{ position: "relative" }}>
                                <InputCheckbox page={"add"} onChange={(e) => storageCharacter(item.id)} id={item.id} width="280px" name={item.name} />
                            </div>


                        )
                    })}
                </div>


            </div>

            <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                <div></div>
                <button className="textBtnTableListStock" style={btnStyle} onClick={() => storage()}>Adicionar imóvel</button>
            </div>

        </div>
    )

}

export default TableListAddStock