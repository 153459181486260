import React from 'react'
import {
    BrowserRouter as Router,
    Routes,
    Route,
  } from "react-router-dom";

/* Pages */
import Landing from '../pages/Landing'
import Dashboard from '../pages/Dashboard'
import Inventary from '../pages/Inventary'

import ManageMent from '../pages/ManageMent'
import EditStock from '../pages/EditStock'
import AddStock from '../pages/AddStock'

import Users from '../pages/Users'
import Requests from '../pages/Requests'

/* Private route */

import PrivateRoute from '../pages/PrivateRoute';


const AppStack = () =>{

    return(

        <Router  >

        <Routes>
        <Route path='/'  element={<Landing/>} />
        <Route path='/dashboard/inventary'  element={<PrivateRoute><Inventary/></PrivateRoute>} />
        <Route path='/dashboard/manager'  element={<PrivateRoute><ManageMent/></PrivateRoute>} />
        <Route path='/dashboard/edit/stock'  element={<PrivateRoute><EditStock/></PrivateRoute>} />
        <Route path='/dashboard/edit/stock/inventory'  element={<PrivateRoute><EditStock/></PrivateRoute>} />
        <Route path='/dashboard/requests'  element={<PrivateRoute><Requests/></PrivateRoute>} />

        <Route path='/dashboard/add/stock'  element={<PrivateRoute><AddStock/></PrivateRoute>} />
        <Route path='/dashboard/users/'  element={<PrivateRoute><Users/></PrivateRoute>} />

        </Routes>
        </Router>

    )

}

export default AppStack