import "./i18n/index";
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import AppStack from './routes/AppStack';
import ModalProvider from './context/modal';
import UserProvider from './context/user';
import FilterProvider from './context/filter';
import CountagesProvider from './context/countages';
import ClientProvider from './context/client';
import DashboardsProvider from './context/dashboards';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <DashboardsProvider>
      <CountagesProvider>
        <FilterProvider>
          <ClientProvider>

            <UserProvider>
              <ModalProvider>
                <AppStack />
              </ModalProvider>
            </UserProvider>
          </ClientProvider>

        </FilterProvider>
      </CountagesProvider>
    </DashboardsProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
