import React, { createContext, useState } from 'react'
import api from '../services/api'

export const DashboardsContext = createContext({})
function DashboardsProvider({ children }) {

    const [countsPerformed, setCountsPerformed] = useState()
    const [progressCounts, setProgressCounts] = useState()

    const [countsMath, setCountsMath] = useState()
    const [countsNotMath, setCountsNotMath] = useState(30)
    const [countsLeftOver, setCountsLeftOver] = useState()

    const [countPrimary, setCountPrimary] = useState()
    const [countSecondary, setCountSecondary] = useState()
    const [countThree, setCountThree] = useState()

    const [countFour, setCountFour] = useState()

    const [theft, setTheft] = useState()
    const [registrationError, setRegistrationError] = useState()
    const [operationError, setOperationError] = useState()


    const getResumeProgress = async (client, type, location) => {
        console.log(client, type, location)
        if (location != undefined ) {
            const response = await api.post("/dashboard/resume/progress/location", {
                "client": client,
                type: type,
                location: location
            })

            countsLeftOver.log(response.data)

            if (response.status == 200) {

                setCountsPerformed(response.data.countsPerformed)
                setProgressCounts(response.data.progressCounts)
            }
        } else if (type) {
            const response = await api.post("/dashboard/resume/progress/type", {
                "client": client,
                type: type
            })

            if (response.status == 200) {

                setCountsPerformed(response.data.countsPerformed)
                setProgressCounts(response.data.progressCounts)
            }
        }
        else {

            const response = await api.post("dashboard/resume/progress", {
                "client": client
            })

            console.log(client, 5555)

            if (response.status == 200) {

                setCountsPerformed(response.data.countsPerformed)
                setProgressCounts(response.data.progressCounts)
            }
        }
    }

    const getResumeProgressAccuracy = async (client, type, location) => {

        if (location != undefined) {
            const response = await api.post("dashboard/accuracy/progress/location", {
                "client": client,
                type: type,
                location: location
            })
            console.log(response)

            if (response.status == 200) {
                console.log(response.data.countsMath)

                setCountsMath(parseInt(response.data.countsMath))
                setCountsNotMath(parseInt(response.data.countsMissing))
                setCountsLeftOver(parseInt(response.data.countsOver))

            }
        } else if (type) {
            const response = await api.post("dashboard/accuracy/progress/type", {
                "client": client,
                type: type
            })
            console.log(response)

            if (response.status == 200) {
                console.log(response.data.countsMath)

                setCountsMath(parseInt(response.data.countsMath))
                setCountsNotMath(parseInt(response.data.countsMissing))
                setCountsLeftOver(parseInt(response.data.countsOver))

            }
        } else {


            const response = await api.post("dashboard/accuracy/progress", {
                "client": client
            })

            if (response.status == 200) {
                console.log(response.data.countsMath)

                setCountsMath(parseInt(response.data.countsMath))
                setCountsNotMath(parseInt(response.data.countsMissing))
                setCountsLeftOver(parseInt(response.data.countsOver))

            }
        }
    }

    const getResumeProgressDetails = async (client, type, location) => {
        if (location != undefined) {
            const response = await api.post("dashboard/details/progress/location", {
                "client": client,
                type: type,
                location:location
            })

            if (response.status == 200) {
                setCountPrimary(response.data.totalFirstCountage)
                setCountSecondary(response.data.totalSecondaryCountage)
                setCountThree(response.data.totalThreeCountage)
                setCountFour(response.data.totalFourCountage)
            }
        }
        else if (type) {
            const response = await api.post("dashboard/details/progress/type", {
                "client": client,
                type: type
            })

            if (response.status == 200) {
                setCountPrimary(response.data.totalFirstCountage)
                setCountSecondary(response.data.totalSecondaryCountage)
                setCountThree(response.data.totalThreeCountage)
                setCountFour(response.data.totalFourCountage)
            }
        }
        else {

            const response = await api.post("dashboard/details/progress", {
                "client": client
            })

            if (response.status == 200) {
                setCountPrimary(response.data.totalFirstCountage)
                setCountSecondary(response.data.totalSecondaryCountage)
                setCountThree(response.data.totalThreeCountage)
                setCountFour(response.data.totalFourCountage)
            }
        }
    }

    const getDivergencesDetails = async (client, type, location) => {

        if (location != undefined) {

            const response = await api.post("dashboard/divergences/progress/location", {
                "client": client,
                "reasonErrors": type,
                type: type,
                location: location
            })

            if (response.status == 200) {

                setTheft(response.data.theftNumber)
                setRegistrationError(response.data.setRegistrationError)
                setOperationError(response.data.operationError)
            }
        }
        else if (type) {

            const response = await api.post("dashboard/divergences/progress", {
                "client": client,
                "reasonErrors": type,
                type: type
            })

            if (response.status == 200) {

                setTheft(response.data.theftNumber)
                setRegistrationError(response.data.setRegistrationError)
                setOperationError(response.data.operationError)
            }
        } else {
            const response = await api.post("dashboard/divergences/progress", {
                "client": client,
                "reasonErrors": type

            })

            if (response.status == 200) {

                setTheft(response.data.theftNumber)
                setRegistrationError(response.data.setRegistrationError)
                setOperationError(response.data.operationError)
            }
        }
    }


    return (
        <DashboardsContext.Provider value={{
            getResumeProgress, countsPerformed, progressCounts,
            getResumeProgressAccuracy, countsMath, countsNotMath,
            countsLeftOver, getResumeProgressDetails, countPrimary,
            countSecondary, countThree, countFour,
            getDivergencesDetails, theft, registrationError,
            operationError
        }}>
            {children}
        </DashboardsContext.Provider>
    )

}

export default DashboardsProvider