import React from 'react'

const InputFilterManager = ({name,type,count,remove,onBlur,value,onChange, index}) => {

    const inputStyle = {

        width:"268px",
        height:"45px",
        borderRadius:"7px",
        border:"1px solid #D7D7D7",
        outline:"none",
        marginBottom:"10px"

    }
    

    return(

        <div style={{display:"flex",alignItems:"center"}}>

        <div style={{position:"relative",top:"10px"}}>

        <div style={{borderRadius:"100%",width:"34px",height:"34px",background:"#123164",display:"flex",alignItems:"center",justifyContent:"center",paddingTop:"15px"}}>
        <p className="textCountInputFilterManager">{index}</p>
        </div>

        </div>

        <div style={{display:"flex",flexDirection:"column",marginLeft:"21px"}}>

        <label className="textPrimaryInputFilterManager">{name}</label>
        <input style={inputStyle} value={value} onChange={onChange} onBlur={onBlur} type={type} />

        </div>

        <div style={{cursor:"pointer",position:"relative",top:"10px",left:"6px"}} onClick={remove}>
        <svg width="20" height="25" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.316 4.01649H15.3276C15.1039 2.88325 14.5119 1.865 13.6514 1.13337C12.791 0.40174 11.7147 0.00146052 10.604 0L8.676 0C7.56527 0.00146052 6.48899 0.40174 5.62855 1.13337C4.76812 1.865 4.17614 2.88325 3.9524 4.01649H0.964C0.708331 4.01649 0.463134 4.12228 0.282349 4.31059C0.101564 4.4989 0 4.7543 0 5.02061C0 5.28692 0.101564 5.54233 0.282349 5.73064C0.463134 5.91895 0.708331 6.02474 0.964 6.02474H1.928V19.0783C1.92953 20.4094 2.43784 21.6855 3.34144 22.6267C4.24503 23.5679 5.47013 24.0974 6.748 24.0989H12.532C13.8099 24.0974 15.035 23.5679 15.9386 22.6267C16.8422 21.6855 17.3505 20.4094 17.352 19.0783V6.02474H18.316C18.5717 6.02474 18.8169 5.91895 18.9977 5.73064C19.1784 5.54233 19.28 5.28692 19.28 5.02061C19.28 4.7543 19.1784 4.4989 18.9977 4.31059C18.8169 4.12228 18.5717 4.01649 18.316 4.01649V4.01649ZM8.676 2.00825H10.604C11.2019 2.00901 11.785 2.20242 12.2733 2.56196C12.7615 2.92151 13.131 3.42957 13.3312 4.01649H5.94884C6.14895 3.42957 6.51847 2.92151 7.00673 2.56196C7.49498 2.20242 8.07806 2.00901 8.676 2.00825V2.00825ZM15.424 19.0783C15.424 19.8773 15.1193 20.6435 14.577 21.2084C14.0346 21.7733 13.299 22.0907 12.532 22.0907H6.748C5.98099 22.0907 5.2454 21.7733 4.70305 21.2084C4.16069 20.6435 3.856 19.8773 3.856 19.0783V6.02474H15.424V19.0783Z" fill="#555555"/>
        <path d="M7.71182 18.0745C7.96749 18.0745 8.21268 17.9687 8.39347 17.7804C8.57425 17.5921 8.67582 17.3367 8.67582 17.0704V11.0456C8.67582 10.7793 8.57425 10.5239 8.39347 10.3356C8.21268 10.1473 7.96749 10.0415 7.71182 10.0415C7.45615 10.0415 7.21095 10.1473 7.03017 10.3356C6.84938 10.5239 6.74782 10.7793 6.74782 11.0456V17.0704C6.74782 17.3367 6.84938 17.5921 7.03017 17.7804C7.21095 17.9687 7.45615 18.0745 7.71182 18.0745Z" fill="#555555"/>
        <path d="M11.5675 18.074C11.8232 18.074 12.0684 17.9682 12.2492 17.7799C12.43 17.5916 12.5315 17.3362 12.5315 17.0699V11.0451C12.5315 10.7788 12.43 10.5234 12.2492 10.3351C12.0684 10.1468 11.8232 10.041 11.5675 10.041C11.3118 10.041 11.0666 10.1468 10.8859 10.3351C10.7051 10.5234 10.6035 10.7788 10.6035 11.0451V17.0699C10.6035 17.3362 10.7051 17.5916 10.8859 17.7799C11.0666 17.9682 11.3118 18.074 11.5675 18.074Z" fill="#555555"/>
        </svg>
        </div>


        </div>

    )

}

export default InputFilterManager