import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BigHead } from '@bigheads/core'
import api from '../../services/api';
import { Tooltip as ReactTooltip } from "react-tooltip";

const InputSearchUser = ({ id, name, mail, client, onBlur, idEdit, onClick }) => {

    const [users, setUsers] = useState([])
    const [usersInCountage, setUserInCountage] = useState([])
    const [userCountage, setUserContage] = useState(false)

    const closeInput = () => {
        setTimeout(() => {
            onClick()
        }, 500);
    }

    const getUsers = async (name) => {

        if (name != undefined) {

            const response = await api.post('users/operation', {
                name: name
            })

            if (response.status == 404) {

                setUsers([])
                return false
            }

            setUsers(response.data)
        }

    }

    const addUserInCountage = async (name, mail) => {

        const response = await api.post("user/countage", {
            "name": name,
            "client": client,
            "mail": mail,
            "id_countage": id

        })

        //window.location.href="/dashboard/manager"


        if (response.status == 200) {
            setUserContage(!userCountage)

            return false
        }



        toast.error('Usuário já existe na contagem', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

        onClick(false)


    }

    const getUsersInCountage = async () => {

        const response = await api.post("user/countages", {
            "client": client,
        })

        setUserInCountage(response.data)


    }

    useEffect(() => {

        getUsers()
        getUsersInCountage()
    }, [])

    return (

        <div onBlur={closeInput} key={id}>
            <ToastContainer />


            {id == idEdit

                ?

                <>
                    <input onChange={(e) => getUsers(e.target.value)} style={{ width: "665px", height: "48px", border: "1px solid #D7D7D7", borderRadius: "7px", paddingLeft: "0.9rem", outline: "none" }} />


                    {users.length > 0

                        ?
                        <div style={{ width: "240px", border: "1px solid #D7D7D7", background: "white", position: "absolute", zIndex: "1000", overflowY: "scroll", height: "150px"}}>



                            {users.map(item => {


                                return (


                                    <div key={item.id} onClick={() => addUserInCountage(item.name, item.mail)} style={{ background: "#F7F9FB", borderRadius: "60px", width: "170.73px", height: "39px", marginTop: "9px", marginLeft: "4px", display: "flex", alignItems: "center", cursor: "pointer" }}>

                                        {item.picture != undefined

                                            ?

                                            <img src={item.picture} style={{ marginLeft: "0.5rem", width: "25px", height: "25px", borderRadius: "100%" }} />
                                            :
                                            <BigHead
                                                width={30}
                                                accessory="shades"
                                                body="chest"
                                                circleColor="blue"
                                                clothing="tankTop"
                                                clothingColor="black"
                                                eyebrows="angry"
                                                eyes="wink"
                                                facialHair="mediumBeard"
                                                graphic="vue"
                                                hair="short"
                                                hairColor="black"
                                                hat="none"
                                                hatColor="green"
                                                lashes="false"
                                                lipColor="purple"
                                                mask="true"
                                                faceMask="true"
                                                mouth="open"
                                                skinTone="brown"
                                            />
                                        }


                                        <p className="textNameTableListManager" style={{ marginLeft: "0.5rem" }}>{item.name}</p>

                                    </div>

                                )

                            })}

                            <div style={{ height: "20px" }}></div>
                        </div>

                        :
                        <></>
                    }


                </>

                :

                <div style={{ minWidth: "665px", width: usersInCountage.length == 1 || usersInCountage.length == 0 ? "420px" : "420px", height: "48px", border: "1px solid #D7D7D7", borderRadius: "7px" }}>
                    <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                        {usersInCountage.map((item, index) => {


                            if (item.id_countage == id) {

                                return (

                                    <div data-tooltip-place='top' data-tooltip-position-strategy='fixed' data-tooltip-id="name" data-tooltip-content={`${index + 1}º ${item.name}`} style={{ display: "flex", alignItems: "center", height: "35px", marginTop: "0.3rem", marginLeft: "0.5rem", marginRight: "0.5rem", backgroundColor: "#E4EBFF", gap: "5px", padding: "0 0 0 12px", borderRadius: "60px" }}>
                                        <ReactTooltip id='name' />
                                        <p className='textCardUserTable' style={{ transform: "translateY(8px)" }}></p>
                                        <div style={{ transform: "translateY(-2px)" }}>
                                            {item.picture != undefined

                                                ?

                                                <img src={item.picture} style={{ marginLeft: "0.5rem", width: "65px", height: "65px", borderRadius: "100%" }} />
                                                :
                                                <BigHead
                                                    width={30}
                                                    accessory="shades"
                                                    body="chest"
                                                    circleColor="blue"
                                                    clothing="tankTop"
                                                    clothingColor="black"
                                                    eyebrows="angry"
                                                    eyes="wink"
                                                    facialHair="mediumBeard"
                                                    graphic="vue"
                                                    hair="short"
                                                    hairColor="black"
                                                    hat="none"
                                                    hatColor="green"
                                                    lashes="false"
                                                    lipColor="purple"
                                                    mask="true"
                                                    faceMask="true"
                                                    mouth="open"
                                                    skinTone="brown"
                                                />
                                            }
                                        </div>

                                        {
                                            /* CHECK THE WORD SIZE */
                                            String(item.name).split("") < 7 ? (
                                                <>
                                                    <p className="textNameTableListManager" style={{ marginLeft: "0.5rem" }}>{item.name}</p>
                                                </>
                                            )
                                                :
                                                /* CHECKS FOR NUMBERS TO IDENTIFY, FOR EXAMPLE: NAME 01, NAME 02 */
                                                String(item.name).split(" ")[1] ? (
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <p className="textNameTableListManager" style={{ marginLeft: "0.5rem", whiteSpace: "nowrap", overflow: "hidden", width: "26px", textOverflow: "ellipsis" }}>{item.name}</p>
                                                        <p className="textNameTableListManager2" style={{ whiteSpace: "nowrap" }}>{String(item.name).split(" ")[String(item.name).split(" ").length -1]}</p>
                                                    </div>
                                                )
                                                    :
                                                    /* IF NO NAMES WITH NUMBERS JUST ADD THE THREE POINTS */
                                                    (
                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                            <p className="textNameTableListManager" style={{ marginLeft: "0.5rem", whiteSpace: "nowrap", overflow: "hidden", width: "43px", textOverflow: "ellipsis" }}>{item.name}</p>
                                                        </div>
                                                    )
                                        }

                                        <p id="close" style={{ transform: "translateY(10px)", height: "100%", width: "25px" }}>
                                            <svg id="close" width="10" height="11" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.54849 4.49999L7.5891 1.45604C7.77784 1.26707 7.77784 0.960688 7.5891 0.77173C7.40036 0.582758 7.09434 0.582758 6.90561 0.77173L3.86532 3.816L0.825045 0.77173C0.636301 0.582758 0.330287 0.582758 0.141558 0.77173C-0.047171 0.960703 -0.0471861 1.26709 0.141558 1.45604L3.18215 4.49999L0.141558 7.54396C-0.0471861 7.73293 -0.0471861 8.03931 0.141558 8.22827C0.330303 8.41723 0.636316 8.41724 0.825045 8.22827L3.86532 5.18399L6.9056 8.22827C7.09434 8.41724 7.40036 8.41724 7.58909 8.22827C7.77783 8.0393 7.77783 7.73292 7.58909 7.54396L4.54849 4.49999Z" fill="black" />
                                            </svg>
                                        </p>

                                    </div>

                                )


                            }

                        })}
                    </div>


                </div>
            }


        </div>
    )
}

export default InputSearchUser