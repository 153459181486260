import { useState } from "react"
import { DashboardsContext } from "../../context/dashboards"
import { useContext } from "react"
import api from "../../services/api"
import { useEffect } from "react"
import { ClientContext } from "../../context/client"
import { useTranslation } from "react-i18next"


const FilterModal = ({ }) => {
    const { t } = useTranslation()
    const { getClient, getTypeCountage, setTypeGet, clientType, setClientType, getTypeCountageLocation, getClientInventary } = useContext(ClientContext)
    const { getResumeProgress, getResumeProgressAccuracy, getResumeProgressDetails, getDivergencesDetails } = useContext(DashboardsContext)

    const [openSelectOne, setOpenSelectOne] = useState(false)
    const [openSelectTwo, setOpenSelectTwo] = useState(false)
    const [openSelectThree, setOpenSelectThree] = useState(false)

    const [selectOne, setSelectOne] = useState("")
    const [selectTwo, setSelectTwo] = useState("")
    const [selectThree, setSelectThree] = useState("")

    const [users, setUsers] = useState([])
    const [locations, setLocations] = useState([])
    const [typeInventary, setTypeInventary] = useState([])

    const getUsers = async (name) => {

        if (name != undefined) {

            const response = await api.post("client/by/name", {
                name: name
            })

            if (response.status == 200) {

                getResumeProgress(response.data[0].name)
                getResumeProgressAccuracy(response.data[0].name)
                getResumeProgressDetails(response.data[0].name)
                getDivergencesDetails(response.data[0].name)
            }

        }

        const response = await api.get("clients")

        const clientVerify = localStorage.getItem("@USER_PERMISSION")

        if (response.status == 404) {
            setUsers([])
            return false
        }

        getResumeProgress(response.data[0].name)
        getResumeProgressAccuracy(response.data[0].name)

        if (clientVerify == "Cliente") {
            const nameUser = localStorage.getItem("@USER_NAME")

            const filter = response.data.filter(item => item.name == nameUser)
            setUsers(filter)
        } else {
            setUsers(response.data)

        }

    }

    const onSelect = (value, setValue, setOpen, open, type) => {
        setValue(value)
        setOpen(!open)

        if (type == 'client') {
            getClient(value, "manager")
            setClientType(value)
            setTypeGet(['client', value])
        }

        if (type == 'location') {
            getTypeCountageLocation(selectOne, value)
            setTypeGet(['location', value])
        }

        if(type == 'inventary'){
            getTypeCountage(selectOne, value)
            setTypeGet(['count', value])
        }

    }

    const getLocation = async () => {
        if (selectOne) {
            try {
                const response = await api.get(`/countage/by/client/location/${selectOne}`)
                const uniqueData = response.data.filter(
                    (item, index, self) => index === self.findIndex((i) => i.location === item.location)
                );

                setLocations(uniqueData);
            } catch (error) {

            }
        }
    }

    const getInventary = async () => {
        if (selectOne) {
            const types = [
                "Inventário geral",
                "Inventário Cíclico",
                "Inventário Clinico",
                "Inventário Hospital",
                "Inventário Distibuidor",
            ]

            setTypeInventary(types);
        }
    }

    useEffect(() => {

        getUsers()

    }, [])

    useEffect(() => {
        getLocation()
        getInventary()
    }, [openSelectOne])


    const container = {
        position: "absolute",
        zIndex: 100,
        left: 0,
        top: 50,
    }

    const content = {
        backgroundColor: "#fff",
        width: "375px",
        borderRadius: "20px",
        border: "1px solid #d7d7d750"
    }

    const contentSelect = {
        padding: "17px 20px",
        display: "flex",
        flexDirection: "column",
        gap: "20px"
    }

    const select = {
        width: "100%",
        border: "1px solid #d7d7d7",
        borderRadius: "9px",
        display: "flex",
        alignItems: "center",
        padding: "13px 12px",
        justifyContent: "space-between",
        marginTop: "11px"
    }

    const boxContentSelect = {
        width: "100%",
        border: '1px solid #d7d7d7',
        borderRadius: "9px",
        marginTop: "10px",
        padding: "17px 20px"
    }



    return (
        <div style={container}>
            <div style={content}>
                <div style={{ width: "100%", borderBottom: "1px solid #d7d7d7" }}>
                    <div style={{
                        padding: "17px 20px"
                    }}>
                        <p className='textFilterCount'>{t('filters')}</p>
                    </div>
                </div>

                <div style={contentSelect}>

                    <div>
                        <p className='textFilterCountSelect'>{t('client')}</p>

                        <div style={select} onClick={() => setOpenSelectOne(!openSelectOne)}>

                            <p style={{color: selectOne &&"#000"}} className='textSelectFilter'>{selectOne ? selectOne : "Selecione"}</p>

                            <div>
                                <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.9054 0.32206C12.8232 0.22001 12.7254 0.139011 12.6176 0.0837346C12.5099 0.0284586 12.3943 0 12.2776 0C12.1609 0 12.0453 0.0284586 11.9376 0.0837346C11.8298 0.139011 11.732 0.22001 11.6498 0.32206L7.60036 5.30868C7.51816 5.41073 7.42037 5.49173 7.31263 5.547C7.20488 5.60228 7.08932 5.63074 6.9726 5.63074C6.85588 5.63074 6.74031 5.60228 6.63257 5.547C6.52482 5.49173 6.42703 5.41073 6.34484 5.30868L2.29534 0.32206C2.21315 0.22001 2.11536 0.139011 2.00762 0.0837346C1.89987 0.0284586 1.78431 0 1.66758 0C1.55086 0 1.4353 0.0284586 1.32755 0.0837346C1.21981 0.139011 1.12202 0.22001 1.03982 0.32206C0.875147 0.526056 0.782715 0.80201 0.782715 1.08965C0.782715 1.37729 0.875147 1.65324 1.03982 1.85724L5.09816 6.85475C5.59551 7.46643 6.26968 7.81 6.9726 7.81C7.67551 7.81 8.34969 7.46643 8.84703 6.85475L12.9054 1.85724C13.07 1.65324 13.1625 1.37729 13.1625 1.08965C13.1625 0.80201 13.07 0.526056 12.9054 0.32206Z" fill="#454545" />
                                </svg>

                            </div>

                        </div>

                        {
                            openSelectOne &&
                            <div style={boxContentSelect}>


                                {
                                    users.map(item => {

                                        return (
                                            <div onClick={() => onSelect(item.name, setSelectOne, setOpenSelectOne, openSelectOne, 'client')}>
                                                <p>{item.name}</p>
                                            </div>
                                        )
                                    })
                                }


                            </div>
                        }
                    </div>

                    <div>
                        <p className='textFilterCountSelect'>{t('locationOption')}</p>

                        <div style={select} onClick={() => setOpenSelectTwo(!openSelectTwo)}>

                            <p style={{color: selectTwo &&"#000"}} className='textSelectFilter'>{selectTwo ? selectTwo : "Selecione"}</p>

                            <div>
                                <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.9054 0.32206C12.8232 0.22001 12.7254 0.139011 12.6176 0.0837346C12.5099 0.0284586 12.3943 0 12.2776 0C12.1609 0 12.0453 0.0284586 11.9376 0.0837346C11.8298 0.139011 11.732 0.22001 11.6498 0.32206L7.60036 5.30868C7.51816 5.41073 7.42037 5.49173 7.31263 5.547C7.20488 5.60228 7.08932 5.63074 6.9726 5.63074C6.85588 5.63074 6.74031 5.60228 6.63257 5.547C6.52482 5.49173 6.42703 5.41073 6.34484 5.30868L2.29534 0.32206C2.21315 0.22001 2.11536 0.139011 2.00762 0.0837346C1.89987 0.0284586 1.78431 0 1.66758 0C1.55086 0 1.4353 0.0284586 1.32755 0.0837346C1.21981 0.139011 1.12202 0.22001 1.03982 0.32206C0.875147 0.526056 0.782715 0.80201 0.782715 1.08965C0.782715 1.37729 0.875147 1.65324 1.03982 1.85724L5.09816 6.85475C5.59551 7.46643 6.26968 7.81 6.9726 7.81C7.67551 7.81 8.34969 7.46643 8.84703 6.85475L12.9054 1.85724C13.07 1.65324 13.1625 1.37729 13.1625 1.08965C13.1625 0.80201 13.07 0.526056 12.9054 0.32206Z" fill="#454545" />
                                </svg>

                            </div>

                        </div>

                        {
                            openSelectTwo &&
                            <div style={boxContentSelect}>

                                {
                                    locations?.map(item => {
                                        return (
                                            <div onClick={() => onSelect(item.location, setSelectTwo, setOpenSelectTwo, openSelectTwo, 'location')}>
                                                <p>{item.location}</p>
                                            </div>
                                        )
                                    })
                                }

                            </div>
                        }
                    </div>

                    <div>
                        <p className='textFilterCountSelect'>{t('typeInventary')}</p>

                        <div style={select} onClick={() => setOpenSelectThree(!openSelectThree)}>

                            <p style={{color: selectThree &&"#000"}} className='textSelectFilter'>{selectThree ? selectThree : "Selecione"}</p>

                            <div>
                                <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.9054 0.32206C12.8232 0.22001 12.7254 0.139011 12.6176 0.0837346C12.5099 0.0284586 12.3943 0 12.2776 0C12.1609 0 12.0453 0.0284586 11.9376 0.0837346C11.8298 0.139011 11.732 0.22001 11.6498 0.32206L7.60036 5.30868C7.51816 5.41073 7.42037 5.49173 7.31263 5.547C7.20488 5.60228 7.08932 5.63074 6.9726 5.63074C6.85588 5.63074 6.74031 5.60228 6.63257 5.547C6.52482 5.49173 6.42703 5.41073 6.34484 5.30868L2.29534 0.32206C2.21315 0.22001 2.11536 0.139011 2.00762 0.0837346C1.89987 0.0284586 1.78431 0 1.66758 0C1.55086 0 1.4353 0.0284586 1.32755 0.0837346C1.21981 0.139011 1.12202 0.22001 1.03982 0.32206C0.875147 0.526056 0.782715 0.80201 0.782715 1.08965C0.782715 1.37729 0.875147 1.65324 1.03982 1.85724L5.09816 6.85475C5.59551 7.46643 6.26968 7.81 6.9726 7.81C7.67551 7.81 8.34969 7.46643 8.84703 6.85475L12.9054 1.85724C13.07 1.65324 13.1625 1.37729 13.1625 1.08965C13.1625 0.80201 13.07 0.526056 12.9054 0.32206Z" fill="#454545" />
                                </svg>

                            </div>

                        </div>

                        {
                            openSelectThree &&
                            <div style={boxContentSelect}>

                                {
                                    typeInventary?.map(item => {
                                        return (
                                            <div onClick={() => onSelect(item, setSelectThree, setOpenSelectThree, openSelectThree, 'inventary')}>
                                                <p>{item}</p>
                                            </div>
                                        )
                                    })
                                }

                            </div>
                        }
                    </div>

                </div>
            </div>

        </div>
    )
}

export default FilterModal