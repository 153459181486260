import React, { useState, useEffect } from 'react'
import InputSelectFilterManager from '../InputSelectFilterManager'
import Input from '../Input'
import InputMaskMoney from '../InputMaskMoney';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../services/api';
import app from '../../firebase/database';
import { useTranslation } from 'react-i18next';
import deleteImageIcon from '../../images/svg/deleteImageIcon.svg'
import loadingGif from '../../images/png/wired-flat-63-home.gif'
import InputCheckbox from '../InputCheckbox';
import { use } from 'i18next';

const TableListEditStock = () => {

    const { t } = useTranslation()

    /* INFORMATION IMMOBILE */
    const [goal, setGoal] = useState()
    
    const [typeImmobile, setTypeImmobile] = useState()
    const [location, setLocation] = useState()
    const [imagesBase64, setImagesBase64] = useState([])
    const [imagesBase64Secondary, setImagesBase64Secondary] = useState([])
    const [bathRoom, setBathroomn] = useState()
    const [description, setDescription] = useState()
    const [beadNum, setBeadNum] = useState()
    const [district, setDistrict] = useState()
    const [suite, setSuite] = useState()
    const [iptu, setIptu] = useState("0")

    const [value, setValue] = useState("0")
    const [squareMeters, setSquareMeters] = useState()
    const [groundMeters, setGroundMeters] = useState("")
    const [vacancy, setVacany] = useState()

    const [emphasis, setEmphasis] = useState()
    const [pet, setPet] = useState()
    const [imagesFirebase, setImagesFirebase] = useState([])
    const [characters, setCharacters] = useState([])
    const [animation,setAnimation] = useState(false)

    /* ERROR MESSAGES */
    const [goalError, setGoalError] = useState()
    const [typeImmobileError, setTypeMobileError] = useState()
    const [districtError, setDistrictError] = useState()
    const [descriptionError, setDescriptionError] = useState()
    const [bathRoomError, setBathRoomError] = useState()
    const [productError, setProductError] = useState()
    const [optionsCheck, setOptionsCheck] = useState([])
    const [suiteError, setSuiteError] = useState()
    const [beadNumError, setBeadNumError] = useState()
    const [vacancyError, setVacancyError] = useState()
    const [squareMetersError, setSquareMetersError] = useState()
    const [groundMetersError, setGroundMetersError] = useState()
    const [valueError, setValueError] = useState()
    const [iptuError, setIptuError] = useState()
    const [imagesBase64Error, setImagesBase64Error] = useState()
    const [emphasisError, setEmphasisError] = useState()
    

    const [zipCode, setZipCode] = useState()
    const [longradouro, setLongradouro] = useState()
    const [state, setState] = useState()
    const [city, setCity] = useState()
    const [street, setStreet] = useState()
    const [number, setNumber] = useState()

    const [goalSelectEdit,setGoalSelectEdit] = useState(false)
    const [typeSelectEdit,setTypeSelectEdit] = useState(false)
    const [emphasisEdit,setTypeEmphasisEdit] = useState(false)


    const selectEdit = () => {
        setGoalSelectEdit(true)
    }

    const setTypeSelectEditValue = () => {
        setTypeSelectEdit(true)
    }
    const setTypeEmphasisEditValue = () => {
        setTypeEmphasisEdit(true)
    }


    const removeImage = (image, imageData) => {

        const array = imagesBase64.slice()
        let index = 0

        array.map((item, i) => {
            if (item?.image && item.image === image) {
                index = i
            } else if (item === imageData) {
                index = i
            }
        })
        array.splice(index, 1)

        setImagesBase64(array)

        setImagesFirebase(array)


        /* const array = imagesBase64.slice()
         array.splice(imagesBase64.indexOf(image), 1)
         setImagesBase64(array)
 
         const arrayFirebase = imagesFirebase.slice()
         arrayFirebase.splice(imagesFirebase.indexOf(image), 1)
         setImagesFirebase(array)*/




    }
    const removeImageSecondary = (image, imageData) => {

        const array = imagesBase64Secondary.slice()

        if(array.length == 1){
            return toast.error('Imóvel precisa ter pelo menos 1 imagem!', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }

        let index = 0

        array.map((item, i) => {
            if (item?.image && item.image === image) {
                index = i
            } else if (item === imageData) {
                index = i
            }
        })
        array.splice(index, 1)

        setImagesBase64Secondary(array)


        /* const array = imagesBase64.slice()
         array.splice(imagesBase64.indexOf(image), 1)
         setImagesBase64(array)
 
         const arrayFirebase = imagesFirebase.slice()
         arrayFirebase.splice(imagesFirebase.indexOf(image), 1)
         setImagesFirebase(array)*/




    }
    const removeImageDatabase = async (id) => {

        const response = await api.delete(`client/immobiles/image/${id}`)
        if (response.status == 200) {

            return toast.success('Imagem removida com sucesso!', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

        }else{
            
            return toast.error('Ops... alog deu errado!', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

        }
    }
    const storage = async () => {

       const formatedValue = value.replace(".", "")
        const formatedValueSecondary = formatedValue.replace(",", "")

        const calcValue = String(formatedValueSecondary / 100)

        const response = await api.put("panel/update/immobile/", {
            "name": localStorage.getItem("@USER_NAME"),
            "goal": goal,
            "type":typeImmobile,
            "description": description,
            "location": location,
            "bead_num": beadNum,
            "bathroom_num": bathRoom,
            "square_meters": squareMeters,
            "ground_meters": groundMeters,
            "value": calcValue,
            "iptu": iptu,
            "emphasis": emphasis,
            "pet": pet,
            "id": localStorage.getItem("@ID_EDIT")
        })

        const responseAddresses = await api.put("panel/update/addresses/",{
            cep:zipCode,
            state:state,
            city:city,
            street:street,
            number:number,
            id_prop:localStorage.getItem("@ID_EDIT")
        })



        if (response.status == 200) {

            
            try {

                setAnimation(true)
                
                await Promise.all(

                    imagesFirebase.map(async item => {
                        await app.storage().ref(`/immobils/images/${item.image.name}`).put(item.image).then(() => {

                            app.storage().ref(`/immobils/images/${item.image.name}`).getDownloadURL().then(async (url) => {


                                const responseImages = await api.post("panel/storage/immobile/image", {
                                    "id_prop": localStorage.getItem("@ID_EDIT"),
                                    "image": url
                                })

                            }).catch((err) => {
                                console.log(err)
                            })
                        })
                    })


                )

            } catch (error) {

                console.log(error)

            }


            optionsCheck.map(async item => {

                await api.post("panel/storage/immobile/character", {
                    "name": item,
                    "id_prop": response.data.id_prop
                })
            })



            toast.success('Imóvel alterado com sucesso!', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

            setTimeout(() => {

                window.location.href = "/dashboard/inventary"
                setAnimation(false)
            }, [3000])

        }

    }
    const getAllCharacters = async () => {

        const response = await api.get("panel/characters")

        if (response.status == 404) {
            return false
        }
        setCharacters(response.data)

    } 
    const storageCharacter = (e) => {

        
        const duplicate = optionsCheck.findIndex(item => item === e)
        if (duplicate == -1) {
            
            setOptionsCheck([e, ...optionsCheck])
        } else {
            const newOptionsCheck = optionsCheck.filter((item, index) => index !== duplicate);
            setOptionsCheck(newOptionsCheck);
        }

    }
    const getImages = (file) => {

        if (imagesBase64.length == 6) {

            toast.error('Limite de imagens atingido!', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });


            return false

        }

        const reader = new FileReader()

        reader.readAsDataURL(file)

        reader.onload = () => {

            const array = [...imagesBase64]

            array.push({ image: reader.result })

            setImagesBase64(array)
        }

        const images = [...imagesFirebase]
        images.push({ image: file })
        console.log(images)
        setImagesFirebase(images)

    }
    const get = async () => {
        const response = await api.get(`panel/immobile/${localStorage.getItem("@ID_EDIT")}`)

        setGoal(response.data[0].goal)
        setEmphasis(response.data[0].emphasis)
        setDescription(response.data[0].description)
        setBathroomn(response.data[0].bathroom_num)
        setBeadNum(response.data[0].bead_num)
        setTypeImmobile(response.data[0].type)
        setSuite(response.data[0].suite_num)
        setVacany(response.data[0].vacancies_num)
        setSquareMeters(response.data[0].square_meters)
        setGroundMeters(response.data[0].ground_meters)
        
        const calcValue = response.data[0].value * 100


        setValue(String(calcValue))
        setIptu(response.data[0].iptu)

        const images = await api.get(`panel/immobiles/images/${localStorage.getItem("@ID_EDIT")}`)

        const addresses = await api.get(`client/immobiles/addresses/${localStorage.getItem("@ID_EDIT")}`)
        setZipCode(addresses.data[0].cep)
        setState(addresses.data[0].state)
        setCity(addresses.data[0].city)
        setLongradouro(addresses.data[0].street)
        setNumber(addresses.data[0].number)
        setImagesBase64Secondary(images.data)

    }
    const getCep = async (cep) => {
        setZipCode(cep)
        const respose = await fetch(`https://viacep.com.br/ws/${cep}/json/`).then(result => result.json())
        setState(respose.uf)
        setCity(respose.localidade)
        setStreet(respose.logradouro)
        setLongradouro(respose.logradouro)
    }

    useEffect(() => {

        get()

    }, [])

    useEffect(() => {

        getAllCharacters()

    }, [])

    const styleColum1 = {

        width: "100%",
        border: "1px solid #D7D7D7",
        borderRadius: "10px",
        paddingLeft: "21px",
        height: "210px",
        paddingTop: "25px",
        background: "white",
    }

    const styleColum2 = {

        marginTop: "2.93rem",
        width: "100%",
        border: "1px solid #D7D7D7",
        borderRadius: "10px",
        paddingLeft: "21px",
        paddingTop: "25px",
        background: "white",

    }

    const styleColum3 = {

        marginTop: "2.93rem",
        width: "100%",
        border: "1px solid #D7D7D7",
        borderRadius: "10px",
        height: "250px",
        paddingLeft: "21px",
        paddingTop: "25px",
        background: "white",

    }

    const styleRowInputs = {
        display: "flex",
        width: "100%",

    }

    const btnStyle = {

        width: "165px",
        height: "45px",
        borderRadius: "60px",
        background: "#123164",
        border: "none",
        marginTop: "2.18rem",
        marginBottom: "2.18rem",
        outline: "none"

    }


    useEffect(() => {

        if (goal == "Selecione") {
            setGoalError("Informe uma finalidade")
        }

        if (typeImmobile == "Selecione") {
            setTypeMobileError("Informe um tipo")
        }

        if (emphasis == "Selecione") {
            setEmphasisError("Informe um valor")
        }

        if (description != undefined) {
            setDescriptionError("")
        }

    }, [goal, typeImmobile, emphasis, description])


    return (

        <div>
            <ToastContainer />

            
            {animation == true 
            
            ?
            <div className='containerLoadingGif'>

                <img src={loadingGif}/>
            
            </div>
               
            :
            <></>
            }


            <div style={styleColum1}>

                <p className="textPrimaryTableListAddStock">{t('informações iniciais')}</p>

                <div style={styleRowInputs}>
                    <div style={{ position: "relative" }}>
                        {goalSelectEdit == false 
                        
                        ?
                        <>
                        <InputSelectFilterManager  onBlur={() => setGoalSelectEdit(false)} onClick={() => selectEdit()} value={goal} type={"goal"} onChange={(e) => setGoal(e.target.value)} color="black" custom={true} top={"43px"} width="295px" name={t('Finalidade')} />
                        <p className="errorMessage">{goalError != undefined ? goalError : <></>}</p>
                        </>
                        :
                 
                        <>
                        <InputSelectFilterManager  onBlur={() => setGoalSelectEdit(false)} onClick={() => selectEdit()}  type={"goal"} onChange={(e) => setGoal(e.target.value)} color="black" custom={true} top={"43px"} width="295px" name={t('Finalidade')} />
                        <p className="errorMessage">{goalError != undefined ? goalError : <></>}</p>
                        </>
                        }
                 
                    </div>
                    
                    {typeSelectEdit == false 
                    
                    ?

                    <div style={{ position: "relative" }}>
                        <InputSelectFilterManager  onClick={() => setTypeSelectEditValue(true) } onBlur={() => setTypeSelectEdit(false)}value={typeImmobile} error={typeImmobileError} onChange={(e) => setTypeImmobile(e.target.value)} type="typeImmobile" color="black" custom={true} top={"43px"} width="295px" name={t('Tipo imóvel')} />
                        <p className="errorMessage">{typeImmobileError != undefined ? typeImmobileError : <></>}</p>
                    </div>                
                    :
                    
                    <div style={{ position: "relative" }}>
                        <InputSelectFilterManager onClick={() => setTypeSelectEditValue(true) } onBlur={() => setTypeSelectEdit(false)}  error={typeImmobileError} onChange={(e) => setTypeImmobile(e.target.value)} type="typeImmobile" color="black" custom={true} top={"43px"} width="295px" name={t('Tipo imóvel')} />
                        <p className="errorMessage">{typeImmobileError != undefined ? typeImmobileError : <></>}</p>
                    </div>

                    }

                    {emphasisEdit == false 
                    
                    ?
                    <div style={{ position: "relative" }}>
                    <InputSelectFilterManager onClick={() => setTypeEmphasisEditValue(true) } type={"emphasis"} onBlur={() => setTypeEmphasisEdit(false)} value={emphasis == 1 ? "Sim" : "Não"} onChange={(e) => setEmphasis(e.target.value)} color="black" custom={true} top={"43px"} width="295px" name={t('Destaque')} />
                    <p className="errorMessage">{emphasisError != undefined ? emphasisError : <></>}</p>
                    </div>
                    :

                    <div style={{ position: "relative" }}>
                    <InputSelectFilterManager onClick={() => setTypeEmphasisEditValue(true) } type={"emphasis"} onBlur={() => setTypeEmphasisEdit(false)} onChange={(e) => setEmphasis(e.target.value)} color="black" custom={true} top={"43px"} width="295px" name={t('Destaque')} />
                    <p className="errorMessage">{emphasisError != undefined ? emphasisError : <></>}</p>
                    </div>

                    }

             

                </div>

            </div>


            <div style={styleColum2}>
                <p className="textPrimaryTableListAddStock">{t('informações endereço')}</p>

                <div style={styleRowInputs}>
                    <div style={{ position: "relative" }}>
                        <Input mask={"99999-999"} value={zipCode} onChange={(e) => getCep(e.target.value)} onBlur={(e) => getCep(e.target.value)} color="black" type="text" name={t('Cep')} width="167.4px" />
                    </div>
                    <div style={{ position: "relative" }}>

                        <Input value={state} onChange={(e) => setState(e.target.value)} color="black" type="text" name={t('Estado')} marginLeft={"20px"} width="167.4px" />
                    </div>
                    <div style={{ position: "relative" }}>
                        <Input value={city} onChange={(e) => setCity(e.target.value)} color="black" type="text" name={t('Cidade')} marginLeft={"20px"} width="167.4px" />
                    </div>
                    <div style={{ position: "relative" }}>
                        <Input value={longradouro} onChange={(e) => setStreet(e.target.value)} color="black" type="text" name={t('Rua')} marginLeft={"20px"} width="167.4px" />
                    </div>

                    <div style={{ position: "relative" }}>
                        <Input value={number} onChange={(e) => setNumber(e.target.value)} color="black" type="text" name={t('Número')} marginLeft={"20px"} width="167.4px" />
                    </div>



                </div>

            </div>


            <div style={styleColum2}>

                <p className="textPrimaryTableListAddStock">{t('informações imóvel')}</p>

                <div style={styleRowInputs}>
                    <div style={{ position: "relative" }}>
                        <Input value={description} onChange={(e) => setDescription(e.target.value)} color="black" type="text" name={t('description')} width="620px" />
                        <p className="errorMessage">{descriptionError != undefined ? descriptionError : <></>}</p>
                    </div>
                    <div style={{ position: "relative" }}>
                        <Input mask={"99999"} error={bathRoomError} value={bathRoom} onChange={(e) => setBathroomn(e.target.value)} color="black" type="text" marginLeft="20px" name={t('Qtd banheiros')} width="295px" />
                        <p className="errorMessage" style={{ marginLeft: "20px" }}>{productError != undefined ? productError : <></>}</p>
                    </div>
                </div>

                <div style={styleRowInputs}>

                    <div style={{ position: "relative" }}>
                        <Input mask={"99999"} value={beadNum} error={beadNumError} onChange={(e) => setBeadNum(e.target.value)} color="black" type="text" marginLeft="0px" name={t('Qtd camas')} width="167.4px" />
                    </div>


                    <div style={{ position: "relative" }}>
                        <Input mask={"99999"} value={suite} error={suiteError} onChange={(e) => setSuite(e.target.value)} color="black" type="text" marginLeft="20px" name={t('Qtd suites')} width="167.4px" />
                    </div>


                    <div style={{ position: "relative" }}>
                        <Input mask={"99999"} value={vacancy} error={vacancyError} onChange={(e) => setVacany(e.target.value)} color="black" type="text" marginLeft="20px" name={t('Qtd vagas')} width="167.4px" />
                    </div>

                    <div style={{ position: "relative" }}>
                        <Input mask={"99999"} value={squareMeters} error={squareMetersError} onChange={(e) => setSquareMeters(e.target.value)} color="black" type="text" marginLeft="20px" name={t('Área construída m²')} width="167.4px" />
                    </div>

                    <div style={{ position: "relative" }}>
                        <Input mask={"99999"} value={groundMeters} error={groundMetersError} onChange={(e) => setGroundMeters(e.target.value)} color="black" type="text" marginLeft="20px" name={t('Área do terreno m²')} width="167.4px" />
                    </div>

                </div>

                <div style={styleRowInputs}>

                    <div style={{ position: "relative" }}>
                        <InputMaskMoney fixed={2} height={"45px"} type="money" error={valueError} value={value} onChange={(e) => setValue(e.target.value)} color="black" name={t('Preço')} width="167.4px" />
                    </div>

                    <div style={{ position: "relative" }}>
                        <InputMaskMoney fixed={2} height={"45px"} type="money" error={iptuError} value={iptu} onChange={(e) => setIptu(e.target.value)} color="black" marginLeft="20px" name={t('Iptu')} width="167.4px" />
                    </div>

                    <div style={{ position: "relative" }}>
                        <Input onChange={(e) => getImages(e.target.files[0])} error={imagesBase64Error} style={{ appearance: "none" }} height={"45px"} color="black" type="file" marginLeft="20px" name={t('Imagem')} width="167.4px" />

                    </div>
                </div>


                <div style={{ display: "flex", flexWrap: "wrap" }}>

                    {/* DATABASE IMAGES */}
                    {imagesBase64Secondary.map(item => {

                        return (
                            <div className='image_preview_base64' style={{ position: "relative", marginRight: "3rem", marginLeft: "0.7rem", marginBottom: "3rem" }}>
                                <img src={item.image} style={{ width: "200px", height: "150px", borderRadius: "3px", marginTop: "32px" }} />

                                <img src={deleteImageIcon} style={{ position: "absolute", top: "20px", right: "-10px", background: "white", zIndex: "1px", borderRadius: "30px", cursor: "pointer" }} onClick={() => { removeImageSecondary(item.id);removeImageDatabase(item.id) }} />

                            </div>
                        )

                    })}


                    {/* LOCAL IMAGES */}
                    {imagesBase64.map(item => {
                        
                        return (
                            <div className='image_preview_base64' style={{ position: "relative", marginRight: "3rem", marginLeft: "0.7rem", marginBottom: "3rem" }}>
                                <img src={item.image} style={{ width: "200px", height: "150px", borderRadius: "3px", marginTop: "32px" }} />

                                <img src={deleteImageIcon} style={{ position: "absolute", top: "20px", right: "-10px", background: "white", zIndex: "1px", borderRadius: "30px", cursor: "pointer" }} onClick={() => { removeImage(item.image, item)}} />

                            </div>
                        )

                    })}

                </div>


            </div>

            <div style={styleColum3}>

                <p className="textPrimaryTableListAddStock">{t('Características imóvel')}</p>



                <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
                    {characters.map(item => {
                        return (

                            <div style={{ position: "relative" }}>
                                <InputCheckbox page={"edit"} onChange={(e) => storageCharacter(item.id)} id={item.id} width="280px" name={item.name} />
                            </div>


                        )
                    })}
                </div>


            </div>

            <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                <div></div>
                <button className="textBtnTableListStock" style={btnStyle} onClick={() => storage()}>Editar imóvel</button>
            </div>

        </div>
    )

}

export default TableListEditStock